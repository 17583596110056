
const sliceStr = (str: string, count: number) => {
  return str.length > count ? `${str.slice(0, count)}....${str.slice(str.length - count, str.length)}` : str
}
const defaultMapValues = {
  center: {
    lat: -37.82168417163056,
    lng: 145.05854992101723
  },
  zoom: 12
};
export const htmlTable = ({ days, times, proposed }: any) => `<table className="table table-hover">
    <thead>
      <tr>
        <th scope="col">Days of the week</th>
        <th scope="col">Proposed change</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td>${days}</td>
        <td>${proposed}</td>
        </tr>
        </tbody>
        </table>`
// <th scope="col">Times</th>
// <td>${times}</td>

const WeekName = [{ label: "All Days", value: "1" }, { label: "Weekdays", value: "2" }, { label: "Weekends", value: "3" }]
const vehiclesType = [{ label: "SUV", value: "SUV" },
{ label: "Hatchback", value: "Hatchback" },
{ label: "Crossover", value: "Crossover" },
{ label: "Convertible", value: "Convertible" },
{ label: "Sedan", value: "Sedan" },
{ label: "Sports Car", value: "Sports Car" },
{ label: "Coupe", value: "Coupe" },
{ label: "Pickup Truck", value: "Pickup Truck" },
{ label: "Minivan", value: "Minivan" },
{ label: "Van", value: "Van" },
]

const StateName = [
  { label: "New South Wales", value: "1" },
  { label: "Queensland", value: "2" },
  { label: "South Australia", value: "3" },
  { label: "Tasmania", value: "4" },
  { label: "Victoria", value: "5" },
  { label: "Western Australia", value: "6" },
]

const CityNames = {
  NewSouthWales: [
    { label: "Sydney", value: "1" }
  ],
  Queensland: [
    { label: "Brisbane", value: "1" },
  ],
  SouthAustralia: [
    { label: "Adelaide", value: "1" },
  ],
  Tasmania: [
    { label: "Hobart", value: "1" },
  ],
  Victoria: [
    { label: "Melbourne", value: "1" },
    // { label: "Ballarat", value: "2" },
  ],
  WesternAustralia: [
    { label: "Perth", value: "1" },
  ]
}
const RoleList = [{ label: "Dashboard", value: "1" }, { label: "Users", value: "2" }, { label: "Clearwayzone", value: "3" }, { label: "Polls", value: "4" }]
const TimeZone = [{ label: "America / Los Angeles", value: "America/Los_Angeles" }, { label: "America / New York", value: "America/New_York" }, { label: "Asia / Tokyo", value: "Asia/Tokyo" }]
const ResultVisiblity = [{ label: "Always public", value: "" }, { label: "Public after end date", value: "1" }, { label: "Public after vote", value: "2" }, { label: "Not public", value: "3" }]
const uiSettings = {
  sliceStr,
  htmlTable,
  WeekName,
  CityNames,
  StateName,
  TimeZone,
  RoleList,
  vehiclesType,
  ResultVisiblity,
  defaultMapValues,
}
export default uiSettings