import Placeholder from "../common/Placeholder"
import henceforthApi from "../../utils/henceforthApi"
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';
import moment from "moment";

type propsType = {
    limit: number
    dataLoading: boolean
    tableData: any
    SerialNo: number
}

const DonationTableDetails = (props: propsType) => {
    const { limit, dataLoading, tableData, SerialNo } = props
    return (
        <>
            <div className='table-responsive px-0 Recent-clearwayzone'>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">User Name </th>
                            <th scope="col">Amount</th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Date</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    {dataLoading ? <tbody> <tr><td colSpan={6}><Placeholder length={10} /></td> </tr></tbody> : <tbody>
                        {Array.isArray(tableData) && tableData.length ? tableData.map((res: any, index: any) =>
                            <tr key={res.id}>
                                <td>
                                    {SerialNo === 0
                                        ? index + 1
                                        : (SerialNo - 1) * limit + (index + 1)}.
                                </td>
                                <td>
                                    <div className="d-flex gap-2 align-items-center">
                                        {<img src={res?.user?.profile_pic ? henceforthApi.FILES.imageSmall(res?.user?.profile_pic) : defaultUserImg} alt="img" className='me-1 staff-table-img' />}
                                        {/* {<img src={res?.user?.profile_pic_thumbnail ? res?.user?.profile_pic_thumbnail : defaultUserImg} alt="img" className='me-1 staff-table-img' />} */}
                                        <span>{res.user.name ? `${res.user.name} ` : `Not Available`}</span>
                                    </div>
                                </td>
                                <td>{res.amount ? `$ ${res.amount}` : 'Not Available'}</td>
                                <td>{res.trx_id ? `${res.trx_id}` : 'Not Available'}</td>
                                <td>
                                    {(moment(res.created_at).format('DD-MM-YYYY'))}
                                </td>
                                <td className={`${res.status === 'succeeded' ? 'draft-status' : res.status === 'closed' ? 'closed-status' : res.status === 'published' ? 'publish-status' : ''} text-capitalize`}>
                                    {res.status}
                                    {/* <span className={res.is_block === 1 || (res.is_deactive
                                        === 1 && res.is_block === 1) ? 'text-black' : res.is_deactive === 1 ? 'closed-status' : ''}>{res.is_block === 1 || (res.is_deactive
                                            === 1 && res.is_block === 1) ? 'Blocked' : res.is_deactive === 1 ? 'Deactive' : ''}
                                    </span>
                                    {res.is_deactive
                                        !== 1 && res.is_block !== 1 && <span className='draft-status'>Active</span>
                                    } */}
                                </td>
                            </tr>)
                            : <tr><td colSpan={9} className="text-center">No data found</td>
                            </tr>
                        }
                    </tbody>}
                </table>
            </div>
        </>
    )
}
export default DonationTableDetails