import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/Provider';
import { useContext, useState } from "react";
import henceforthApi from '../../utils/henceforthApi';
import loginSuccess from '../../context/actions/auth/loginSuccess';
import camera from '../../assets/icons/camera_red.svg'
import Spinner from '../common/BootstrapCompo';
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';

import { goBack } from '../../utils/CommonFunctions';

const EditProfile = () => {
    const navigate = useNavigate()
    const { authState, authDispatch, toastMessage } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        name: authState.name,
        email: authState.email,
        phone_number: authState.phone_number,
    });

    henceforthApi.setToken(authState.access_token);
    const fileUpload = async (file: any) => {
        try {
            const apiImageRes = await henceforthApi.Common.uploadFile("profile_pic", file)
            loginSuccess({ profile_pic: apiImageRes.image })(authDispatch);
        } catch (error) {
            console.log(error);
        }
    }
    const handlesubmit = async (e: any) => {
        e.preventDefault();
        const data = {
            name: state.name,
            email: state.email,
        } as any
        if (authState.profile_pic) data['profile_pic'] = authState.profile_pic
        setLoading(true)
        try {
            let apires = await henceforthApi.Auth.edit(data)
            loginSuccess(apires.data)(authDispatch);
            toastMessage(apires?.message)
            navigate('/profile')

        } catch {
            console.log("error")
        } finally {
            setLoading(false)
        }


    }

    // const handlesubmit = async (e: any) => {
    //     e.preventDefault();
    //     // debugger
    //     let apiImageRes = {} as any
    //     setLoading(true)
    //     if (image) {
    //         apiImageRes = await henceforthApi.Common.uploadFile("profile_pic", image)
    //         loginSuccess({ profile_pic: apiImageRes.image })(authDispatch);
    //     }
    //     const data = {
    //         name: state.name,
    //         email: state.email,
    //     } as any
    //     if (image) {
    //         data['profile_pic'] = apiImageRes.image
    //     }
    //     // if (authState.profile_pic) data['profile_pic'] = authState.profile_pic
    //     try {
    //         let apires = await henceforthApi.Auth.edit(data)
    //         loginSuccess(apires.data)(authDispatch);
    //         toastMessage(apires?.message)
    //         navigate('/profile')
    //     } catch {
    //         console.log("error")
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const handlechnage = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setState({
            ...state,
            [name]: value
        })
    }

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            <h3 className='py-4 px-4 border-bottom bg-white'>Profile</h3>

                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing py-4'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-5">
                                {/* title  */}
                                <div className="common-card bg-white pb-3 border rounded-1">
                                    <div className="common-card-title border-bottom px-4 pb-3">
                                        <h5>Edit Admin Profile  </h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content">
                                        {/* Upload image */}
                                        <div className='upload-fields-box mb-3'>
                                            <div className='profile-edit-image text-center mb-2 py-4'>
                                                <div className="edit-pofile-image">
                                                    <img src={authState.profile_pic ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.profile_pic}` : defaultUserImg} alt="img" className='edit-profile-image img-fluid' />
                                                </div>

                                                <div className='profile-edit-upload'>
                                                    <img src={camera} alt="image" className='img-fluid' />
                                                    <input type="file" onChange={(e: any) => fileUpload(e.target.files[0])} accept='image/png,image/jpeg,image/jpg' />
                                                </div>
                                            </div>
                                        </div>
                                        <form onSubmit={handlesubmit} className='px-4'>
                                            {/* Name */}
                                            <div className='form-fields-box mb-3'>
                                                <label className='form-label fs-12'>
                                                    Name
                                                </label>
                                                <input type="text" className="form-control border" value={state.name as any} onChange={handlechnage} name="name" required placeholder='John Doe' />
                                            </div>
                                            {/* Email */}
                                            <div className='form-fields-box mb-4 pb-2'>
                                                <label className='form-label fs-12'>
                                                    Email
                                                </label>
                                                <input type="email" className="form-control border" value={state.email as any} onChange={handlechnage} name="email" disabled />
                                            </div>

                                            {/* Submit Button  */}
                                            <div className='signin-button-box'>
                                                <ul className='list-unstyled d-flex gap-3'>
                                                    <li><button type='button' className='btn border py-2 px-4' onClick={goBack} >Cancel</button></li>
                                                    <li> <button type='submit' className='btn btn-primary py-2' disabled={loading}>{loading ? <Spinner /> : 'Save Changes'}</button></li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default EditProfile;