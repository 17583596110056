import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import henceforthApi from '../../utils/henceforthApi'
import PaginationLayout from '../common/PaginationLayout'
import view from '../../assets/icons/view_blue.svg'
import edit from '../../assets/icons/edit_green.svg'
import { handleSearch, onChangePagination } from '../../utils/CommonFunctions'
import Placeholder from '../common/Placeholder'
import { Pagination } from 'antd'


const VehicleListing = () => {

    const location = useLocation();
    const navigate = useNavigate()
    const newParam = new URLSearchParams(location.search);
    const [dataLoading, setDataLoading] = useState(false)

    const match = useMatch("/vehicle/:page");
    const limit = 10
    const [pageData1, setpageData1] = useState<any>({
        data: [],
        totalCount: 0
    })

    console.log(pageData1);

    const initialise = async () => {
        setDataLoading(true)
        try {
            const apires = await henceforthApi.Vehicles.pagination(
                newParam.toString(),
                Number(match?.params.page),
            )
            setpageData1({
                data: apires.data.data,
                totalCount: apires.data.total
            })
        } catch {
            console.log("error")
        } finally {
            setDataLoading(false)
        }
    }

    useEffect(() => {
        initialise()
    }, [match?.params.page, newParam.get("search"), newParam.get("filter")])

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="common-card-title border-bottom px-4 py-4 bg-white d-flex justify-content-between align-items-center">
                                <h3>Vehicles</h3>
                                <Link to='/vehicle/add'>
                                    <button className='btn btn-primary'>Add Vehicle</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='product-listing'>
                    <div className="container-fluid">
                        {/* search-filter-export */}
                        <div className="row">
                            <div className='col-12'>
                                <div className='common-card mb-4 border-0 card-spacing px-2 px-sm-4 '>
                                    <div className="row justify-content-between bg-white border rounded">

                                        {/* header */}
                                        <div className='col-12 px-0'>
                                            <div className='staff-header border-bottom d-flex justify-content-between align-items-center border-bottom py-3 px-4'>
                                                <h5>Vehicle Management</h5>
                                                {/* <div>
                                                    <div className="dropdown">
                                                        <button className="btn shadow-none dropdown-toggle border py-1 px-3 fw-500 fs-12 rounded " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {newParam.has('filter') ? filterArray.find((res) => res.id === newParam.get('filter'))?.name : `All Staff's`}&nbsp;
                                                        </button>
                                                        <ul className="dropdown-menu py-2 map-dropdown">
                                                            {filterArray.map((res) => <li onClick={() => handleSearch('filter', res.id, location, navigate, newParam)}><button className="dropdown-item">{res.name}</button></li>)}
                                                        </ul>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* serach and filter  */}
                                        <div className="col-12">
                                            <div className='position-relative py-3 px-2'>
                                                <input type="search" value={newParam.has('search') ? newParam.get('search') as string : ''} className="form-control rounded-0 ps-4 " name='search' placeholder="Search by vehicle Name or Model"
                                                    onChange={(e: any) => handleSearch(e.target.name, e.target.value, location, navigate, newParam)}
                                                />
                                            </div>
                                        </div>
                                        {/* table */}
                                        <div className='col-12 px-0'>
                                            <div className='table-responsive'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Sr. No.</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Model</th>
                                                            <th scope="col">Model Year</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    {dataLoading ? <tbody> <tr><td colSpan={6}><Placeholder length={10} /></td> </tr></tbody> : <tbody>
                                                        {Array.isArray(pageData1.data) && pageData1.data.length ? pageData1.data.map((res: any, index: any) =>
                                                            <tr key={res.id}>
                                                                <td>
                                                                    {Number(match?.params.page) == 0
                                                                        ? index + 1
                                                                        : (Number(match?.params.page) - 1) * limit + (index + 1)}
                                                                </td>
                                                                <td>
                                                                    <div className=" me-5">
                                                                        {res.car ? res.car : `Not Available`}
                                                                    </div>
                                                                </td>

                                                                <td>{res.type ? `${res.type}` : 'Not Available'}</td>

                                                                <td>{res.model ? `${res.model}` : 'Not Available'}</td>
                                                                <td>{res.model_year ? `${res.model_year}` : 'Not Available'}</td>
                                                                <td><div className="btn-group gap-2">
                                                                    <Link to={`/vehicle/${res.id}/view`} className='btn view-btn border'>
                                                                        <img src={view} className='img-fluid me-2' alt='image' />
                                                                        <span className='align-middle fs-12'>View</span>
                                                                    </Link>
                                                                    <Link to={`/vehicle/${res.id}/edit`} className='btn   view-btn border'>
                                                                        <img src={edit} className='img-fluid me-2' alt='image' />
                                                                        <span className='align-middle fs-12'>Edit</span>
                                                                    </Link>
                                                                </div>
                                                                </td>
                                                            </tr>) : <tr><td colSpan={9} className="text-center">No data found</td></tr>}

                                                    </tbody>}
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* pagination */}
                        {/* <PaginationLayout
                            count={pageData1.totalCount}
                            data={pageData1.data}
                            limit={limit}
                            page={Number(match?.params.page)}
                        /> */}
                        <div className="text-center">
                            <Pagination current={Number(match?.params.page)} className='text-primary' onChange={(e: any) => onChangePagination(e, location, navigate, newParam)} total={pageData1.totalCount} showSizeChanger={false} />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default VehicleListing;