import { useMatch } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import henceforthApi from '../../utils/henceforthApi'
import { GlobalContext } from '../../context/Provider'
import { toast } from 'react-toastify'
import camera from '../../assets/icons/camera_red.svg'
import { goBack } from '../../utils/CommonFunctions'
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';

import { rolesValue } from './add-staff'
import Spinner from '../common/BootstrapCompo'
import Placeholder from '../common/Placeholder'

const EditStaff = () => {

    const { authState } = useContext(GlobalContext);
    const match = useMatch("staff/:_id/edit")
    const [loading, setLoading] = useState(false)

    const [role, setRole] = useState<string[]>([])
    const [state, setState] = useState({
        _id: "",
        name: "",
        image: "",
        email: "",
        country_code: "",
        profile_pic: '',
        phone_number: "",
        profile_pic_url: "",
        super_admin: false,
        is_blocked: false,
        is_deleted: false,
        created_at: ""
    })

    const [selectedFile, setSelectedFile] = useState(null as any)

    henceforthApi.setToken(authState.access_token)

    const handleInput = (e: any) => {
        let value = e.target.value
        let prev = role;
        let itemIndex = prev?.indexOf(value);
        if (itemIndex !== -1) {
            prev?.splice(itemIndex, 1);
        } else {
            prev.push(value);
        }
        prev.sort()
        setRole([...prev]);
    };


    const handleChange = ({ target }: any) => {
        setState({
            ...state,
            [target.name]: target.value
        })
    }

    const updateProfile = async () => {
        const items = {
            _id: match?.params._id,
            name: state.name,
            email: state.email,
            roles: role,
        } as any
        let id = match?.params._id
        setLoading(true)

        try {
            if (selectedFile) {
                const imageRes = await henceforthApi.Common.uploadFile("profile_pic", selectedFile)
                items.profile_pic = imageRes.image
            } else if (state.profile_pic) {
                items.profile_pic = state.profile_pic
            }
            const apiRes = await henceforthApi.Staff.edit(id, items)
            toast.success(apiRes.message)
            window.history.back()
        } catch (error: any) {

            if (error.response.body.message.roles) return toast.error(error.response.body.message.roles[0])
            if (error.response.body.message.email) return toast.error(error.response.body.message.email[0])

        } finally {
            setLoading(false)
        }
    }

    const initialise = async () => {
        setLoading(true)
        try {
            const apiRes = await henceforthApi.Staff.get(match?.params?._id as string)
            setState(apiRes.data)
            setRole(apiRes.data.access_role_name)
        } catch (error: any) {
            console.log(error);
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        initialise()
    }, [])

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <h3 className="fw-semibold px-4 py-4 bg-white border-bottom">Edit Staff</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-5">
                                {/* title  */}

                                <div className="common-card bg-white rounded border">
                                    <div className="common-card-title py-3 px-4 border-bottom">
                                        <h5>Edit Staff</h5>
                                    </div>
                                    {/* form  */}
                                    {loading ? <Placeholder length={10}></Placeholder> :
                                        <div className="common-card-content p-4">
                                            <form onSubmit={(e) => { e.preventDefault(); updateProfile() }}>
                                                {/* Upload image */}
                                                <div className='upload-fields-box mb-3'>
                                                    <div className='profile-edit-image text-center mb-4 '>
                                                        <div className="edit-pofile-image">
                                                            <img src={selectedFile ? URL.createObjectURL(selectedFile) : state?.profile_pic ? henceforthApi.FILES.imageSmall(state?.profile_pic) : defaultUserImg} alt="img" className='edit-profile-image img-fluid' />
                                                        </div>
                                                        <div className='profile-edit-upload'>
                                                            <img src={camera} alt="image" className='img-fluid' />
                                                            <input type="file" onChange={(e: any) => setSelectedFile(e.target.files[0])} accept='image/png,image/jpeg,image/jpg' />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Name */}
                                                <div className='form-fields-box mb-3'>
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                                                    <input type="text" className="form-control rounded-0" id="exampleFormControlInput1" placeholder='Name' value={state.name} onChange={handleChange} name="name" required />
                                                </div>
                                                <div className='form-fields-box mb-3'>
                                                    <label htmlFor="exampleFormControlInput2" className="form-label">Email</label>
                                                    <input type="text" className="form-control rounded-0" id="exampleFormControlInput2" placeholder='Email' value={state.email} onChange={handleChange} name="email" required />
                                                </div>
                                                <div className='form-radio-box mb-3'>
                                                    <div className='form-check-inner d-flex gap-4 flex-wrap'>
                                                        {
                                                            rolesValue?.map((res: any, index: number) =>
                                                                <div key={index} className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value={res?.value}
                                                                        checked={role.includes(String(res.value))}
                                                                        onChange={(e: any) => handleInput(e)} id={`roleslist${index}`} />
                                                                    <label className="form-check-label ms-1" htmlFor={`roleslist${index}`}>
                                                                        {res.role}
                                                                    </label>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {/* Button  */}

                                                <div className='signin-button-box'>
                                                    <ul className='list-unstyled d-flex gap-3'>
                                                        <li><button type='button' className='btn border py-2 px-4' onClick={goBack} >Cancel</button></li>
                                                        <li> <button type='submit' className='btn btn-primary py-2' disabled={loading} >{loading ? <Spinner /> : 'Save Changes'}</button></li>
                                                    </ul>
                                                </div>
                                            </form>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </>
    )
}
export default EditStaff;

