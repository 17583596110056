import React, { Fragment } from 'react';
import { GlobalContext, handleError } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';

const Database = () => {
    const { authState, downloadFile } = React.useContext(GlobalContext)
    const [loading, setLoading] = React.useState(false)

    henceforthApi.setToken(authState.access_token)


    const downloadDb = async () => {

        try {
            setLoading(true)
            const apiRes = await henceforthApi.Common.backupDb({ language: "ENGLISH" })
            await downloadFile(`${henceforthApi.API_ROOT}admin/download-database/${apiRes.database}`)
            // await downloadFile(`${'http://52.63.150.108:8081/api/'}admin/download-database/${apiRes.database}`)
            // await downloadFile(`${henceforthApi.Common.downloadDb}/${apiRes.database}`)
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Fragment>
            {/* breadcrum  */}
            {/* <BreadCrumb pathNameDeclare={breadCrumbPath} /> */}
            <section className="breadcrum-box py-0 border-bottom">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between py-4 bg-white">
                            {/* title  */}
                            <div className="common-card-title px-3">
                                <h3>Database Backup</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='product-listing'>
                    <div className="container-fluid">
                        {/* Order list table  */}
                        <div className="row justify-content-center">
                            <div className="col-sm-11 col-md-8 col-lg-6 col-xl-5 col-xxl-5 ">
                                <div className="common-card database">
                                    <div className="common-card-title  border-bottom">
                                        <h5>Database Backup</h5>
                                    </div>
                                    <div className="common-card-content text-center  p-3">
                                        <p className='mb-2'>If you click on Download, Database will be downloaded.</p>
                                        <p>Db MySql file: <button className='btn btn-primary' onClick={downloadDb} disabled={loading}>Download</button></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <DownloadFileModal exportData={exportData} /> */}

        </Fragment>
    )
}
export default Database;