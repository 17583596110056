import { Fragment, useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default ({ url }: any) => {

    const navigate = useNavigate()
    useEffect(() => {
        navigate(url, { replace: true })
    }, [])
    return <Fragment>

    </Fragment>
}