import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Space, Switch } from 'antd';
import henceforthApi from '../../utils/henceforthApi';
import { toast } from 'react-toastify';
import { disabledDate } from './EditPolls';
import dayjs from 'dayjs';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};

function CreatePoll() {
    const moment = require('moment');
    const [toggleDate, setToggleDate] = useState<boolean>(false)
    const [multipleOption, setMultipleOption] = useState(false)
    const [visibility, setVisibility] = useState('')
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [status, setStatus] = useState('')
    const [createLoading, setCreateLoading] = useState(false)
    const [draftLoading, setDraftLoading] = useState(false)

    const onChange = (date: any) => {
        setEndDate(moment(date.$d).format('YYYY-MM-DD'))
    };

    const onFinish = async (values: any) => {
        // if (!toggleDate) return toast.warn('End date required')
        let items = {
            title: values.title,
            visiblity: 1,
            // multi_select: multipleOption ? 1 : '',
            answers: values.answers
        } as any
        if (status === "Draft") {
            items['status'] = 2
            setDraftLoading(true)
        }
        else {
            items['status'] = 1
            setCreateLoading(true)
        }

        // if (toggleDate) {
        items.end_date = endDate
        // }
        try {
            let resAPi = await henceforthApi.Poll.add(items)
            window.history.back()
            toast.success(resAPi.message)
        } catch (error: any) {
            toast.error(error.response.body.message.title[0])
        }
        finally {
            setCreateLoading(false)
            setDraftLoading(false)
        }
    };

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="common-card-title border-bottom px-4 py-4 bg-white d-flex justify-content-between">
                                <h3>Create a Poll</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='product-listing'>
                    <div className="container-fluid">
                        {/* search-filter-export */}
                        <div className='common-card border-0 card-spacing border p-4 px-4 px-sm-5 mx-sm-3 rounded bg-white'>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* header */}
                                    <Form layout="vertical" onFinish={onFinish} >

                                        <div className='col-12 px-0'>
                                            <div className="title mb-3">
                                                <label htmlFor="" className='mb-2 fw-400'>Title</label>
                                                <Form.Item name="title" >
                                                    <Input className='w-100 form-control' placeholder="Type your question here" />
                                                </Form.Item>
                                            </div>
                                            <div className='answer-option'>
                                                <div className="row">
                                                    <div className="col-6">

                                                    </div>
                                                </div>
                                                <h6 className='mb-3 fw-400'>Answer Options</h6>

                                                <Form.List
                                                    name="answers"
                                                    rules={[
                                                        {
                                                            validator: async (_, names) => {
                                                                if (!names || names.length < 2) {
                                                                    return Promise.reject(new Error('Add at least 2 option'));
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {(fields, { add, remove }, { errors }) => (
                                                        <>
                                                            {fields.map((field, index) => (
                                                                <Form.Item
                                                                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                                    // label={index === 0 ? 'Options' : ''}
                                                                    required={false}
                                                                    key={field.key}
                                                                >
                                                                    <Form.Item
                                                                        {...field}
                                                                        // style={{ width: '100%' }}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                whitespace: true,
                                                                                message: `Please input ${index === 0 ? 'First' : 'Second'} option`,
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Input placeholder="write an option here" style={{ width: '100%' }} className='form-control' />
                                                                    </Form.Item>
                                                                    {fields.length ? (
                                                                        <CloseOutlined
                                                                            className="dynamic-delete-button" style={{ position: 'relative', right: '25px' }}
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    ) : null}
                                                                </Form.Item>
                                                            ))}
                                                            <Button
                                                                type="dashed"
                                                                disabled={fields.length === 2}
                                                                onClick={() => add()}
                                                            >
                                                                Add Option
                                                            </Button>
                                                            <Form.Item>
                                                                <Form.ErrorList errors={errors} />
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </div>
                                        <hr className='mt-4 mb-4' />
                                        <div className="col-12">
                                            <div className="Settings mb-4">
                                                <h4>Expiry Date</h4>
                                            </div>
                                            <div className="row gy-4">
                                                <div className="col-lg-6">
                                                    {/* <div className="form-check form-switch mb-4 d-flex justify-content-between p-0">
                                                    <p>Allow selection of multiple options</p>
                                                    <Switch className='switch' onChange={(e: any) => setMultipleOption(e)} />
                                                </div> */}
                                                    <div className="date">
                                                        {/* <div className="form-check form-switch mb-2 d-flex justify-content-between p-0">
                                                            <h6>Set end date</h6>
                                                            <Switch className='switch' onChange={(e: any) => setToggleDate(e)} />
                                                        </div> */}
                                                        {/* {toggleDate &&  */}
                                                        <Space direction="vertical" className='w-100'>
                                                            <DatePicker
                                                                format='YYYY-MM-DD'
                                                                className='w-100 form-control'
                                                                defaultValue={dayjs(endDate, 'YYYY-MM-DD')}
                                                                disabledDate={disabledDate}
                                                                placeholder='YYYY-MM-DD'
                                                                onChange={onChange}
                                                                inputReadOnly
                                                            />
                                                        </Space>
                                                        {/* // } */}
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6">
                                                <div className="result">
                                                    <h6 className='mb-4 fw-400'>Results visibility</h6>
                                                    <Select
                                                        className="form-control px-3 py-2"
                                                        value={visibility}
                                                        style={{ width: '100%' }}
                                                        placeholder="Please select"
                                                        onChange={setVisibility}
                                                        options={uiSettings.ResultVisiblity}
                                                    />
                                                </div>
                                            </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className='d-flex gap-2 flex-wrap mt-2'>
                                                        <li>
                                                            <Button type="primary" value='large' className='btn btn-theme' htmlType="submit" style={{ height: '40px' }} loading={draftLoading} disabled={(createLoading || draftLoading)}
                                                                onMouseOver={() => setStatus('Draft')}>Save as Draft
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            {/* <Form.Item> */}
                                                            <Button type="primary" value='large' className='btn btn-primary' htmlType="submit" style={{ height: '40px' }}
                                                                onMouseOver={() => setStatus('Create')} loading={createLoading} disabled={(createLoading || draftLoading)}>
                                                                <span className='align-middle'> Create Poll</span>
                                                            </Button>
                                                            {/* </Form.Item> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Form></div>
                            </div>
                        </div>
                    </div>

                </section >
            </div >
        </>
    )
}

export default CreatePoll