import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import henceforthApi from '../../utils/henceforthApi'
import { handleError } from '../../context/Provider'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const FaqList = () => {

    const [state, setState] = useState({
        data: [],
        question: ""
    })



    const initailse = async () => {
        try {

            let apiRes = await henceforthApi.Faq.get()
            setState(apiRes.data)
        } catch (error) {
            handleError(error)
        }
    }
    const onChangeDelete = async (_id: any) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    let apiRes = await henceforthApi.Faq.delete(_id)
                    toast.success(apiRes.data.message)
                    initailse()
                } catch (error) {
                    handleError(error)
                } finally {

                }
            }
        })
    }

    useEffect(() => {
        initailse()
    }, [])




    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            {/* title  */}
                            <h2 className="fw-semibold">FAQ</h2>
                            {/* breadcrum  */}
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active fw-bold">FAQ</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='product-listing'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h5>FAQ</h5>
                                            {/* add faq  */}
                                            <Link to="/add-faq" className='btn btn-white btn-sm'> <i className='fa fa-plus'></i> Add</Link>
                                        </div>
                                    </div>
                                    <div className="common-card-content">
                                        <div className='faq-content-box product-faq-accodion'>
                                            <div className="accordion" id="faqAccordion">
                                                {/* 1 */}
                                                {Array.isArray(state.data) && (state.data.length) ? state.data.map((res: any, index: any) => {

                                                    return (
                                                        <>
                                                            <div className="accordion-item bg-transparent border border-1 mb-3 position-relative" key={index}>
                                                                <h2 className="accordion-header" id="faqOne">
                                                                    <button className="accordion-button shadow-none bg-transparent text-black" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${res._id}`} aria-expanded="true" aria-controls="collapseOne">
                                                                        <b className='me-1'>Q.</b> {res.question}?

                                                                    </button>
                                                                </h2>
                                                                {/* show */}
                                                                <div id={`collapseOne${res._id}`} className="accordion-collapse collapse " aria-labelledby="faqOne" data-bs-parent="#faqAccordion">
                                                                    <div className="accordion-body border border-1 bg-transparent">
                                                                        <p className='d-flex text-black'><b className='me-1 hellos'>A.</b><span className='hellos text-black' dangerouslySetInnerHTML={{ __html: res.answer }} /> </p>
                                                                    </div>
                                                                </div>
                                                                <ul className="edit-icon-accordion d-flex gap-3 align-items-center mb-0 ps-0 list-unstyled">
                                                                    <li><button className='btn p-0 bg-transparent border-0' onClick={() => onChangeDelete(res._id)}  ><i className='fa fa-trash fs-5'></i></button></li>
                                                                    <li> <Link to={`/edit-faq/${res._id}?question=${res.question}&answer=${res.answer}`}><i className='fa fa-edit fs-5' style={{ cursor: 'pointer' }}></i></Link></li>
                                                                </ul>
                                                            </div>
                                                        </>
                                                    )
                                                }) : <div className='text-center'>Data is not found</div>}



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default FaqList