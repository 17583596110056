import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import henceforthApi from '../../utils/henceforthApi';
import uiSettings from '../../utils/uiSettings';
import HenceforthGoogleMap from './HenceforthGoogleMap';
import { handleSearch, onChangePagination } from '../../utils/CommonFunctions';
import ClearwayzoneListTable from './ClearwayzoneListTable';
import DownloadFileModal from '../common/download-file-modal';
import { GlobalContext, handleError } from '../../context/Provider';
import { Pagination } from 'antd';



function ClearwayzoneList() {

    const defaultProps = {
        center: {
            lat: -37.82168417163056,
            lng: 145.05854992101723
        },
        zoom: 12
    };
    const limit = 10
    const match = useMatch("/clearwayzone/:page");
    const navigate = useNavigate()
    const locationSearchRef = useRef(null as any)
    const googleMapRef = useRef(null as any)
    const location = useLocation()
    const newParam = new URLSearchParams(location.search)
    const [dataLoading, setDataLoading] = useState(false)
    const [inputValue, setInputValue] = useState('12:00 PM');
    const [googleApiLoaded, setGoogleApiLoaded] = useState(false)
    const { downloadCSV } = useContext(GlobalContext)
    const [onAddressChanged, setOnAddressChanged] = useState(defaultProps)
    const [state, setState] = React.useState({
        data: [],
        total: 0
    })

    useEffect(() => {
        if (inputValue === "10:00 PM") {
            alert("You selected 10:00 PM");
        }
    }, [inputValue]);

    const selectedMarkersShow = async ({ id, name, location, days_name, timezone, start_time, end_time, description, locations }: any, googleMapRef: any) => {
        const stepDisplay = new google.maps.InfoWindow;
        const path = locations.map((res: any) => (
            { lat: Number(res.lat), lng: Number(res.lng) })
        );
        const clearwayPolyline = new google.maps.Polyline({
            path,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });
        let days = uiSettings.WeekName.find(res => res.value === days_name[0])?.label
        const info = {
            path,
            days,
            proposed: description,
        } as any
        // times: `${start_time} - ${end_time}, ${timezone}`,

        clearwayPolyline.setMap(googleMapRef.current.map_);
        google.maps.event.addListener(clearwayPolyline, 'click', (evt: any) => {
            stepDisplay.setContent(uiSettings.htmlTable(info));
            stepDisplay.setPosition(evt.latLng);
            stepDisplay.open(googleMapRef.current.map_);
        })
        return clearwayPolyline
    }

    const initialise = async () => {
        setDataLoading(true)
        try {
            let apiRes = {} as any
            if (!newParam.has('view')) {
                apiRes = await henceforthApi.ClearWayaZone.pagination(Number(match?.params.page), limit, newParam.has('search') ? `search=${newParam.get('search')}` : '')
            } else {
                apiRes = await henceforthApi.ClearWayaZone.pagination('', '', '')
            }
            const apiData = apiRes.data
            setState(apiData)
            const { current_page, data, from, to, total } = apiData

            if (Array.isArray(data)) {
                data.forEach((res) => {
                    selectedMarkersShow(res, googleMapRef)
                })
            }
            // setOnAddressChanged((onAddressChanged: any) => {
            //     return {
            //         ...onAddressChanged,
            //         center: {
            //             lat: data[0]?.locations[0]?.lat,
            //             lng: data[0]?.locations[0]?.lng
            //         },
            //         zoom: Zoom,
            //     }
            // })
        } catch (error) {

        } finally {
            setDataLoading(false)
        }
    }
    useEffect(() => {
        if (googleApiLoaded) {
            initialise()
        }
    }, [match?.params.page, newParam.get('search'), newParam.get('view')])

    const initPlaceAPI = () => {
        if (locationSearchRef) {
            let autocomplete = new (window as any).google.maps.places.Autocomplete(
                locationSearchRef.current
            );
            new (window as any).google.maps.event.addListener(
                autocomplete,
                "place_changed",
                () => {
                    let place = autocomplete.getPlace();
                    let formatAddress = place.formatted_address
                    const address = place.address_components
                    console.log(address);
                    let items: any = {}
                    if (Array.isArray(address) && address.length > 0) {
                        let zipIndex = address.findIndex(res => res.types.includes("postal_code"))
                        let administrativeAreaIndex = address.findIndex(res => res.types.includes("administrative_area_level_1", "political"))
                        let localityIndex = address.findIndex(res => res.types.includes("locality", "political"))
                        let countryIndex = address.findIndex(res => res.types.includes("country", "political"))
                        let premiseIndex = address.findIndex(res => res.types.includes("premise", "street_number"))
                        let sublocality1 = address.findIndex(res => res.types.includes('sublocality_level_1', 'sublocality', 'political'))
                        let sublocality2 = address.findIndex(res => res.types.includes('sublocality_level_2', 'sublocality', 'political'))
                        let route = address.findIndex(res => res.types.includes('route'))
                        let subpremise = address.findIndex(res => res.types.includes('subpremise'))
                        let street_number = address.findIndex(res => res.types.includes('street_number'))
                        if (zipIndex > -1) {
                            items.pin_code = address[zipIndex]?.long_name
                        }
                        if (administrativeAreaIndex > -1) {
                            items.state = address[administrativeAreaIndex]?.long_name
                        }
                        if (localityIndex > -1) {
                            items.city = address[localityIndex]?.long_name
                        }
                        if (countryIndex > -1) {
                            items.country = address[countryIndex]?.long_name
                        }
                        if (premiseIndex > -1) {
                            items.apartment_number = address[premiseIndex]?.long_name
                        }
                        items.full_address = formatAddress
                        items.sublocality1 = address[sublocality1]?.long_name
                        items.sublocality2 = address[sublocality2]?.long_name
                        items.subpremise = address[subpremise]?.long_name
                        items.route = address[route]?.long_name
                        items.street_number = address[street_number]?.long_name
                        let zoom = 12
                        if (items?.country && items?.state && items?.city && items?.sublocality1 && (items?.sublocality2 || items?.route) && (items?.subpremise || items?.street_number)) zoom = 18
                        if (items?.country && items?.state && items?.city && items?.sublocality1 && (items?.sublocality2 || items?.route) && items?.subpremise === undefined && items?.street_number === undefined) zoom = 18
                        if (items?.country && items?.state && items?.city && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 15
                        if (items?.country && items?.state && items?.city === undefined && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 8
                        if (items?.country && items?.state === undefined && items?.city === undefined && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 5
                        // setLocationAddress(items?.full_address ? items?.full_address : `${items?.city},${items?.state},${items?.country}`)
                        setOnAddressChanged((onAddressChanged: any) => {
                            return {
                                ...onAddressChanged,
                                center: {
                                    ...onAddressChanged.center,
                                    lat: place?.geometry?.location?.lat(),
                                    lng: place?.geometry?.location?.lng(),
                                },
                                zoom: zoom
                            }
                        })

                    }

                }
            );
        }
    }

    const onGoogleApiLoaded = ({ map, maps, ref }: any) => {
        setGoogleApiLoaded(true)
        initPlaceAPI()
    }

    const filterArray = [
        { id: '', name: 'List View' },
        { id: '1', name: 'Map View' },
    ]

    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.ClearWayaZone.export(startDate, endDate)
            const data = apiRes.data
            downloadCSV(data)
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        initialise()
    }, [match?.params.page, newParam.get('search'), newParam.get('view')])

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="d-flex justify-content-between px-4 py-4 bg-white border-bottom">
                                <h3>Clearwayzone</h3>
                                <Link to={`/clearwayzone/add`}>
                                    <button className='btn btn-primary px-3 py-2'>Add Clearwayzone</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/****************** Recent Clearwayzone  *******************/}
            <section className='Recent-clearwayzone px-2 px-sm-4 '>
                <div className="container-fluid">
                    <div className="row bg-white border rounded">
                        <div className='col-12 px-0'>
                            <div className='title d-flex justify-content-between px-4 py-3 align-items-center border-bottom'>
                                <h5 className='m-0'>{newParam.has('view') ? 'Map' : 'List'} of All Clearway Zones</h5>
                                {/* drop-down */}
                                <div className="d-flex align-items-center gap-3">
                                    <div className="dropdown">
                                        <button className="btn shadow-none dropdown-toggle border py-1 px-3 fw-500 fs-12 rounded " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {newParam.has('view') ? filterArray.find((res) => res.id === newParam.get('view'))?.name : 'List View'}&nbsp;
                                        </button>
                                        <ul className="dropdown-menu py-2 map-dropdown">
                                            {filterArray.map((res) => <li onClick={() => handleSearch('view', res.id, location, navigate, newParam)}><button className="dropdown-item">{res.name}</button></li>)}
                                        </ul>
                                    </div>
                                    <div className='d-flex gap-3 justify-content-md-end '>
                                        <div className='download-export-box text-center'>
                                            {/* <label className='mb-1 form-label fw-semibold'>Export File</label> */}
                                            <div className="export-button border rounded">
                                                <button className="btn shadow-none py-1 px-3  fs-12 rounded border-0" type="button" data-bs-toggle="modal" data-bs-target="#fileDownloadModal"> <i className='fa fa-cloud-download me-2 align-middle'></i>
                                                    <span>.csv</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="dropdown">
                                    <button className="btn shadow-none dropdown-toggle p-0 border py-1 px-3 fw-500 fs-12 rounded " type="button" data-bs-toggle="dropdown" aria-expanded="false">  
                                    List View &nbsp;                                  
                                    </button>
                                    <ul className="dropdown-menu py-0 overflow-hidden py-2">
                                        <li><Link className="dropdown-item" to="/profile">List View</Link></li>
                                        <li><Link className="dropdown-item" to="/profile">Map View</Link></li>
                                    </ul>
                                </div> */}
                                {/* select */}
                                {/* <div className='list-view'>
                                    <select className="form-select py-1" value={toggleMap} onChange={(e) => setToggleMap(Number(e.target.value))} aria-label="Default select example">
                                        <option value={1} >List View </option>
                                        <option value={2}>Map View</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className='px-2 py-3 d-flex gap-3'>
                                <input type="search" className={`form-control ${!newParam.has('view') ? 'd-block' : 'd-none'}`} placeholder='Search by Name, Country , State & City' name='search' onChange={({ target }: any) => handleSearch(target.name, target.value, location, navigate, newParam)} />
                                <input type="search" className={`form-control ${newParam.has('view') ? 'd-block' : 'd-none'}`} ref={locationSearchRef} placeholder='Search Clearway Via Country , State & City' />
                                {/* <div className='timepicker-ui position-relative' ref={tmRef}>
                                    <input
                                        type='test'
                                        className='opacity-0 timepicker-ui-input form-control  position-absolute top-0'
                                        defaultValue={inputValue}

                                    />
                                    <div className='form-control d-flex gap-2 align-items-center h-100'>
                                        <img src={filter} alt="icons" height='12px' width='12px' />
                                        <span> Filter</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* cleaway-table */}

                        <div className='table-responsive px-0' style={{ display: !newParam.has('view') ? 'block' : 'none' }}>
                            <ClearwayzoneListTable
                                limit={limit}
                                dataLoading={dataLoading}
                                tableData={state.data}
                                SerialNo={Number(match?.params?.page)}
                            />
                        </div>
                        <div style={{ height: "62vh", width: "100%", display: newParam.get('view') == '1' ? 'block' : 'none' }}>
                            <HenceforthGoogleMap
                                ref={googleMapRef}
                                center={onAddressChanged.center}
                                zoom={onAddressChanged.zoom}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                onGoogleApiLoaded={useMemo(() => onGoogleApiLoaded, [])}
                            />
                        </div>

                    </div>
                    {/* pagination */}
                    {!newParam.has('view') ? <div className='text-center  pt-5'>
                        <Pagination current={Number(match?.params.page)} className='text-primary' onChange={(e: any) => onChangePagination(e, location, navigate, newParam)} total={state.total} showSizeChanger={false} />
                    </div> : ''}

                </div>


            </section>
            <DownloadFileModal exportData={exportData} />
        </>
    )
}

export default ClearwayzoneList