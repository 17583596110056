import React from "react"
import { useLocation, useNavigate } from "react-router-dom"


export const goBack = () => {
    window.history.back()
}
export const onChangePagination = (page: number, location: any, navigate: any, newParam: any) => {
    if (location.pathname.startsWith('/users')) {
        navigate({
            pathname: `/users/${page}`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/staffs')) {
        navigate({
            pathname: `/staffs/${page}`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/polls')) {
        navigate({
            pathname: `/polls/${page}`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/clearwayzone')) {
        navigate({
            pathname: `/clearwayzone/${page}`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/contact-us')) {
        navigate({
            pathname: `/contact-us/${page}`,
            search: newParam.toString()
        })
    }
    else if (location.pathname.startsWith('/donations')) {
        navigate({
            pathname: `/donations/${page}`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/vehicle')) {
        navigate({
            pathname: `/vehicle/${page}`,
            search: newParam.toString()
        })
    } else {
        navigate({
            pathname: `/products/${page}`,
            search: newParam.toString()
        })
    }

}
export const handleSearch = (name: string, value: string, location: any, navigate: any, newParam: any) => {

    if (value) {
        if (name === "product_id") newParam.delete("search")
        if (name === 'start_date') newParam.delete('end_date')
        newParam.set(name, value)
    } else {
        if (newParam.has(name)) {
            newParam.delete(name)
        }
        if (name === 'start_date') {
            newParam.delete('end_date')
        }
    }
    if (location.pathname.startsWith('/users')) {
        navigate({
            pathname: `/users/1`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/staffs')) {
        navigate({
            pathname: `/staffs/1`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/polls')) {
        navigate({
            pathname: `/polls/1`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/clearwayzone')) {
        navigate({
            pathname: `/clearwayzone/1`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/contact-us')) {
        navigate({
            pathname: `/contact-us/1`,
            search: newParam.toString()
        })
    }
    else if (location.pathname.startsWith('/donations')) {
        navigate({
            pathname: `/donations/1`,
            search: newParam.toString()
        })
    } else if (location.pathname.startsWith('/vehicle')) {
        navigate({
            pathname: `/vehicle/1`,
            search: newParam.toString()
        })
    }
    else if (location.pathname === '/') {
        navigate({
            search: newParam.toString()
        })
    }
}