import { ComposedChart, Area, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const Graph = ({ data, totalsales, totalhours, graph, type }: any) => {


    return (
        <ResponsiveContainer width={'100%'} height={300}>
            <ComposedChart width={600} height={300} data={data}>
                <XAxis dataKey={"date"} stroke="gray" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey={totalhours} fill="#28a7453d" stroke="#41b15acc" />
                <Bar dataKey={totalsales}
                    barSize={50} fill="#ff0000" />
            </ComposedChart>
        </ResponsiveContainer>
    )
}
export default Graph;
