import { useState } from "react";
import henceforthApi from "../../utils/henceforthApi";
import { toast } from "react-toastify";
import Spinner from "../common/BootstrapCompo";
import { goBack } from "../../utils/CommonFunctions";

const ChangePassword = () => {

    const [oldPasswordError, setoldPasswordError] = useState("")
    const [NewPasswordError, setNewPasswordError] = useState("")
    const [confirmPasswordError, setconfirmPasswordError] = useState("")
    const [state, setState] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        loading: false,
    });
    const handleInput = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (oldPasswordError) {
            setoldPasswordError("")
        }
        if (NewPasswordError) {
            setNewPasswordError("")
        } if (confirmPasswordError) {
            setconfirmPasswordError("")
        }
        setState({
            ...state,
            [name]: value,
        });
    };

    const ChangePassword = async (e: any) => {
        e.preventDefault()

        if (!state.oldPassword) {
            return setoldPasswordError("Please enter oldPassword")
        }
        if (!state.newPassword) {
            return setNewPasswordError("Please enter  password")
        }
        if (!state.confirmNewPassword) {
            return setconfirmPasswordError("Plase enter vaild password")
        }
        if (state.newPassword !== state.confirmNewPassword) {
            return toast.warn("Password and confirm password not match")
        }
        setState({
            ...state,
            loading: true
        })
        try {
            const items = {
                language: "ENGLISH",
                old_password: state.oldPassword,
                new_password: state.newPassword,
                confirm_new_password: state.confirmNewPassword,
            }

            let apiRes = await henceforthApi.Auth.changePassword(items)
            toast.success(apiRes.message)
            goBack()
        } catch (error: any) {
            if (error.response.body.message.new_password) return toast.error(error.response.body.message.new_password[0])
            if (error.response.body.message) return toast.error(error.response.body.message)
            if (error.response.body.message.password) return toast.error(error.response.body.message.password[0])

        } finally {
            setState({
                ...state,
                loading: false
            })
        }
    }
    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <h3 className="py-4 px-4 bg-white border-bottom">Change Password</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing' >
                <section className='change-password'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-5">
                                <div className="common-card">
                                    <div className="common-card-content border p-4 bg-white rounded">
                                        <form onSubmit={ChangePassword}>
                                            {/* old Password */}
                                            <div className='form-fields-box mb-3'>
                                                <label htmlFor="Current-Password" className="mb-2">Current Password </label>
                                                <div className="input-group is-invalid p-0 border rounded overflow-hidden">

                                                    <input type="password"
                                                        name="oldPassword"
                                                        onChange={handleInput}
                                                        value={state.oldPassword}
                                                        className={`form-control rounded-0 ${oldPasswordError ? " is-invalid" : ""} border-0`} placeholder="Current Password" />
                                                </div>
                                                {/* error msg  */}
                                                <div className={`${oldPasswordError ? "invalid-feedback" : ""}`}>
                                                    {oldPasswordError}
                                                </div>
                                            </div>
                                            {/* New Password */}
                                            <div className='form-fields-box mb-3 '>
                                                <label htmlFor="Current-Password" className="mb-2">New Password </label>
                                                <div className="input-group is-invalid p-0 border rounded overflow-hidden">
                                                    <input type="password"
                                                        name="newPassword"
                                                        onChange={handleInput}
                                                        value={state.newPassword}
                                                        className={`form-control rounded-0 border-0 ${NewPasswordError ? "is-invalid" : ""} border-end-0`} placeholder="New Password" />
                                                </div>
                                                {/* error msg  */}
                                                <div className={`${NewPasswordError ? "invalid-feedback" : ""}`}>
                                                    {NewPasswordError}
                                                </div>
                                            </div>
                                            {/* Confirm New Password */}
                                            <div className='form-fields-box mb-4 pb-2'>
                                                <label htmlFor="Current-Password" className="mb-2">Confirm Password </label>
                                                <div className="input-group is-invalid  p-0 border rounded overflow-hidden">
                                                    <input type="password"
                                                        //  type={thirdEye ? "password" : "text"}
                                                        name="confirmNewPassword"
                                                        onChange={handleInput}
                                                        value={state.confirmNewPassword}
                                                        className={`form-control rounded ${confirmPasswordError ? "is-invalid" : ""} border-0`} placeholder="Confirm New Password" />
                                                </div>
                                                {/* error msg  */}
                                                <div className={`${confirmPasswordError ? "invalid-feedback" : ""}`}>
                                                    {confirmPasswordError}
                                                </div>
                                            </div>
                                            {/* Submit Button  */}
                                            <div className='signin-button-box'>
                                                <ul className='list-unstyled d-flex gap-3'>
                                                    <li><button type='button' className='btn btn-theme py-2' onClick={goBack}>Cancel</button></li>
                                                    <li> <button type='submit' className='btn btn-primary py-2' disabled={state.loading}>{state.loading ? <Spinner /> : "Update Password"}</button></li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ >
    )
}

export default ChangePassword;