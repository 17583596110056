import { Link, useMatch, } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react';
import henceforthApi from '../../utils/henceforthApi';
import { handleError } from '../../context/Provider'
import { ToastContainer } from 'react-toastify'
import Swal from 'sweetalert2';
import Spinner from '../common/BootstrapCompo';
import moment from 'moment';
import Placeholder from '../common/Placeholder';
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';

const UserDetails = () => {

    const match = useMatch("/user/:id")
    const [state, setstate] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const initialize = async () => {
        if (!loaded) {
            setLoading(true)
        }
        try {

            let apires = await henceforthApi.User.get(
                match?.params.id as string
            )
            setstate(apires.data)
        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
            setstate((state: any) => {
                return {
                    ...state,
                    Deleteloading: false,
                    ActiveLoading: false,
                    Blockloading: false,
                }
            })
        }
    }

    const handelUserEvents = (value: string, message: string) => {
        Swal.fire({
            title: `Are you sure you want to ${message} user ${state.name}`,
            showCancelButton: true,
            // icon: 'error',
            // titleText: 'hello',
            confirmButtonColor: "#FE0002",
            cancelButtonColor: "transparent",
            confirmButtonText: "Yes, Confirm",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    if (value === 'handleBlock') {
                        setstate((state: any) => {
                            return {
                                ...state,
                                Blockloading: true
                            }
                        }); await henceforthApi.User.blockUnblock(match?.params?.id as string); initialize()

                    }
                    else if (value === 'handleActive') {
                        setstate((state: any) => {
                            return {
                                ...state,
                                ActiveLoading: true
                            }
                        }); await henceforthApi.User.activeDeactive(match?.params?.id as string); initialize()
                    }
                    else {
                        setstate({
                            ...state,
                            Deleteloading: true
                        })
                        await henceforthApi.User.delete(match?.params.id as string)
                        window.history.back()
                    }

                } catch (error) {
                    handleError(error)
                } finally {
                }
            }
        })


    }

    useEffect(() => {
        setLoaded(true)
        initialize()
    }, [])

    console.log(state?.vehicles?.length);

    return (
        <Fragment>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <ToastContainer autoClose={1000} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="d-flex justify-content-between align-items-center border-bottom py-3 px-4 bg-white">
                                <div className="">
                                    <h3 className="fw-semibold">User</h3>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item ">
                                                <Link className="text-decoration-none text-black" to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item ">
                                                {/* <h2 className="fw-semibold text-secondary">User</h2> */}
                                                <Link className="not-allowed text-decoration-none text-secondary" to="#">User Details</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            {/* <h3 className="fw-semibold p-4 bg-white border-bottom">User</h3> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            {loading ? <Placeholder length={12}></Placeholder> : <div className='page-spacing '>
                <section className='change-password'>
                    <div className="container-fluid">
                        <div className="row py-3 justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-5">
                                {/* Profile Card  */}
                                <div className="common-card bg-white border rounded">
                                    <div className="common-card-title py-3 px-4 border-bottom">
                                        <h6>User Details</h6>
                                    </div>
                                    {/* Profile  */}
                                    <div className="common-card-content px-4">
                                        {/* Profile image  */}
                                        <div className="profile-image text-center py-4 border-bottom">
                                            <div className="edit-pofile-image">
                                                <img src={state.profile_pic ? henceforthApi.FILES.imageSmall(state?.profile_pic) : defaultUserImg} alt="img" className='img-fluid' />
                                            </div>
                                        </div>
                                        {/* Profile Detail  */}
                                        {loading ? <Placeholder length={3} /> : <div className="profile-details my-4">
                                            <div className='row gy-1 gy-sm-3'>
                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Name :</label>
                                                        <p>{state?.name}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Email :</label>
                                                        <p>{state?.email}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Phone Number :</label>
                                                        <p>{state.phone_number ? `${state.country_code} ${state.phone_number}` : 'Not Available'} </p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Joined Date :</label>
                                                        <p>{state.created_at ? moment(state.created_at).format('YYYY-MM-DD') : 'Not Available'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Address:</label>
                                                        <p>{state.location ? state.location : 'Not Available'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Country :</label>
                                                        <p>{state.country ? state.country : 'Not Available'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>State :</label>
                                                        <p>{state.state ? state.state : 'Not Available'}</p>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Postcode  :</label>
                                                        <p>{state.postcode ? state.postcode : 'Not Available'}</p>
                                                    </div>
                                                </div>
                                                {state?.vehicles?.length === 0 && < div className="col-12">
                                                    <div className="name d-flex align-items-center gap-2 gap-sm-4 flex-wrap">
                                                        <label htmlFor="" className='text-gray'>Vehicles  :</label>
                                                        <p>Not Available</p>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>}
                                        {/* button  */}
                                        <div className="profile-button pb-4 pt-2">
                                            <ul className='list-unstyled d-flex gap-2  flex-wrap'>
                                                <li>
                                                    <button type='button' className='btn btn-outline-danger' onClick={() => handelUserEvents('Delete', 'Delete')} disabled={state.Deleteloading || state.ActiveLoading || state.Blockloading} >
                                                        {state.Deleteloading || loading ? <Spinner /> : 'Delete'}
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type='button' className='btn btn-outline-dark' onClick={() => handelUserEvents('handleBlock', state.is_block === 1 ? 'Unblock' : ' Block')}
                                                        disabled={state.Deleteloading || state.ActiveLoading || state.Blockloading}>
                                                        {state.Blockloading || loading ? <Spinner /> : <span>{state.is_block === 1 ? 'Unblock' : ' Block'}</span>}
                                                    </button >
                                                </li >
                                                <li>
                                                    <button type='button' className='btn btn-outline-secondary ' onClick={() => handelUserEvents('handleActive', state.is_deactive === 1 ? 'Activate' : ' Deactivate')} disabled={state.Deleteloading || state.ActiveLoading || state.Blockloading}>
                                                        {state.ActiveLoading || loading ? <Spinner /> : <span>{state.is_deactive === 1 ? 'Activate' : ' Deactivate'}</span>}
                                                    </button>
                                                </li>
                                            </ul >
                                        </div >
                                    </div >
                                </div >
                            </div >

                            {state?.vehicles?.length !== 0 && <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-7 align-items-center">
                                <div className="border">
                                    <h4 className='text-center fw-bold border-bottom vehicles-title'>
                                        Vehicles
                                    </h4>
                                    <div className='table-responsive px-0 Recent-clearwayzon'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Brand</th>
                                                    <th scope="col">Model</th>
                                                    <th scope="col">Number</th>
                                                    <th scope="col">Color</th>
                                                </tr>
                                            </thead>
                                            {state?.vehicles?.map((res: any, index: any) =>
                                                <tbody>
                                                    <tr key={res.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{res.type} </td>
                                                        <td>{res.brand}</td>
                                                        <td>{res.model}</td>
                                                        <td>{res.number}</td>
                                                        <td>{res.color}</td>
                                                    </tr>
                                                </tbody>)}
                                        </table>
                                    </div>
                                </div>
                            </div>}
                        </div >
                    </div >
                </section >
            </div >}
        </Fragment >
    )
}
export default UserDetails;