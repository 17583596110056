import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import Graph from '../common/graph';
import users from '../../assets/icons/user_red.svg';
import clearwayzone from '../../assets/icons/clearwayzone_red.svg'
import polls from '../../assets/icons/poll_red.svg';
import UserTableDetails from '../user/UserTableDetails';
import ClearwayzoneListTable from '../map/ClearwayzoneListTable';
import { handleSearch } from '../../utils/CommonFunctions';


const filterArray = [
  { id: '1', name: 'Daily' },
  { id: '2', name: 'Weekly' },
  { id: '3', name: 'Monthly' },
  { id: '4', name: 'Yearly' },
]

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const { authState } = React.useContext(GlobalContext);
  const newParam = new URLSearchParams(location.search);
  const limit = 10
  henceforthApi.setToken(authState.access_token);

  const [dashBoardData, setDashBoardData] = useState<any>([])

  const [userData, setUserData] = useState<any>([])
  const [clearWayData, setClearWayData] = React.useState({
    data: [],
    total: '' as any
  })

  const [dataLoading, setDataLoading] = useState(false)
  const [parkingData, setParkingData] = useState([])
  const [sellerdata, setSellerdata] = useState([])
  const [productdata, setProductdata] = useState([])
  const [type, setType] = useState('Daily')

  const [loading, setLoading] = useState({
    sales: false,
    user: false,
    seller: false,
    product: false,
  })

  // if (newParam.get('filter') === '1') {
  //   setType('Daily')
  // } else if (newParam.get('filter') === '2') {
  //   setType('Monthly')
  // }

  const getParkingData = async (type: number) => {
    try {
      let apires = await henceforthApi.Graph.salegraph(type)
      setParkingData(apires.data)
      // navigate({ search: newParam.toString() })
    } catch {
      console.log("error")
    } finally {
      setLoading(state => {
        return {
          ...state,
          sales: false,
          user: false,
          seller: false,
          product: false,
        }
      })
    }
  }

  const initaliseDashboard = async () => {
    setDataLoading(true)
    try {
      let resApi = await henceforthApi.Dashboard.count()
      setDashBoardData(resApi.data)
    }
    catch (error) {
    }
    finally {
      setDataLoading(false)

    }
  }

  const initaliseClearwayData = async () => {
    try {
      let apiRes = await henceforthApi.ClearWayaZone.pagination(1, limit, '')
      let apiData = apiRes.data
      setClearWayData(apiData)
    } catch (error) {

    }
  }

  const initaliseUsersData = async () => {
    try {
      let apires = await henceforthApi.User.pagination(
        newParam.toString(),
        1,
        limit
      )
      setUserData(apires.data)
    } catch (error) {

    }
  }


  useEffect(() => {
    if (authState.access_role_name.includes('1')) {
      initaliseDashboard()
    }
    // getParkingData(1)
    if (authState.access_role_name.includes('3')) {
      initaliseClearwayData()
    }

    if (authState.access_role_name.includes('2')) {
      initaliseUsersData()
    }

  }, [])

  useEffect(() => {
    if (authState.access_token) {
      getParkingData(newParam.get('filter') ? Number(newParam.get('filter')) : 1)
    }
    if (newParam.get('filter') === '1') {
      setType('Daily')
    } else if (newParam.get('filter') === '2') {
      setType('Weekly')
    } else if (newParam.get('filter') === '3') {
      setType('Monthly')
    } else if (newParam.get('filter') === '4') {
      setType('Yearly')
    }
  }, [newParam.get('filter')])


  return (
    <>
      {/* breadcrum  */}
      <section className="breadcrum-box py-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 px-0">
              {/* title  */}
              <h3 className="p-4 bg-white border-bottom">Dashboard</h3>
            </div>
          </div>
        </div>
      </section>
      {/****************** Dashboard-Graph  *******************/}
      <section className='dashboard-graph px-sm-3'>
        <div className="container-fluid">
          <div className='row gy-4 pb-4'>
            {/* card-1 */}
            <Link to="/users/1" className="col-sm-6 col-md-4 text-decoration-none text-dark">
              <div className="user-card d-flex justify-content-between align-items-center p-4 mb-2">
                <div>
                  <h2 className='text-primary fw-bold mb-4'>{dashBoardData.all_user ? dashBoardData.all_user : 0}</h2>
                  <h5 className='m-0'>User&apos;s</h5>
                </div>
                <div>
                  <img src={users} className='img-fluid' alt='image' />
                </div>
              </div>
            </Link>
            {/* card-2 */}
            <Link to="/clearwayzone/1" className="col-sm-6 col-md-4 text-decoration-none text-dark">
              <div className="user-card d-flex justify-content-between align-items-center p-4">
                <div>
                  <h2 className='text-primary fw-bold mb-4'>{dashBoardData.clearways ? dashBoardData.clearways : 0}</h2>
                  <h5 className='m-0'>Clearwayzone</h5>
                </div>
                <div>
                  <img src={clearwayzone} className='img-fluid' alt='image' />
                </div>
              </div>
            </Link>
            {/* card-3 */}
            <Link to="/polls/1" className="col-sm-6 col-md-4 text-decoration-none text-dark">
              <div className="user-card d-flex justify-content-between align-items-center p-4">
                <div>
                  <h2 className='text-primary fw-bold mb-4'>{dashBoardData.polls ? dashBoardData.polls : 0}</h2>
                  <h5 className='m-0'>Poll&apos;s</h5>
                </div>
                <div>
                  <img src={polls} className='img-fluid' alt='image' />
                </div>
              </div>
            </Link>
          </div>
          {/* parking */}
          <div className="row">
            {/* Sales Graph  */}
            <div className="col-md-12">
              <div className="common-card bg-white border rounded">
                <div className="common-card-title d-flex justify-content-between align-items-center border-bottom py-3 px-4">
                  <h5 className='m-0'>Parking</h5>
                  <div className="dropdown">
                    <button className="btn shadow-none dropdown-toggle border py-1 px-3 fw-500 fs-12 rounded " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {newParam.has('filter') ? filterArray.find((res) => res.id === newParam.get('filter'))?.name : 'Daily'}&nbsp;
                    </button>
                    <ul className="dropdown-menu py-2 overflow-hidden">
                      {filterArray.map((res, index) => <li key={index} onClick={() => { handleSearch('filter', res.id, location, navigate, newParam); setType(res.name) }}><button className="dropdown-item">{res.name}</button></li>)}
                    </ul>
                  </div>
                </div>
                <div className="common-card-content">
                  <Graph data={parkingData} totalsales="parkings" totalhours={type} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/****************** Recent Clearwayzone  *******************/}
      <section className='Recent-clearwayzone px-4 mx-2 '>
        <div className="container-fluid">
          <div className="row bg-white border rounded">
            <div className='col-12 px-0'>
              <h5 className='px-4 py-3 border-bottom'>Recent Clearwayzone </h5>
            </div>
            {/* cleaway-table */}
            <div className='table-responsive px-0'>
              <ClearwayzoneListTable
                limit={limit}
                dataLoading={dataLoading}
                tableData={clearWayData?.data}
                SerialNo={0}
              />
              {clearWayData?.total > 10 && <div className='view-more-btn text-center pt-2 pb-4'>
                <Link to='clearwayzone/1' className='text-black'>
                  <button className='btn border bg-lightgray px-3 fs-12 py-1 rounded' >View more</button>
                </Link>
              </div>}
            </div>

          </div>
        </div>
      </section>
      {/****************** Recent users  *******************/}
      <section className='Recent-users Recent-clearwayzone px-4 mx-2 '>
        <div className="container-fluid">
          <div className="row bg-white border rounded">
            <div className='col-12 px-0'>
              <h5 className='px-4 py-3 border-bottom'>Recent User&apos;s </h5>
            </div>
            {/*User-table */}
            <UserTableDetails
              limit={limit}
              dataLoading={dataLoading}
              tableData={userData.data}
              SerialNo={0}
            />
            {userData?.total > 10 && <div className='view-more-btn text-center pt-2 pb-4'>
              <Link to='users/1' className='text-black'>
                <button className='btn border bg-lightgray px-3 fs-12 py-1 rounded'>View more</button>
              </Link>
            </div>}
          </div>
        </div>
      </section>
      {/* </div> */}
    </>
  );
};

export default Home;
