import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import henceforthApi from '../../utils/henceforthApi'
import { GlobalContext, handleError } from '../../context/Provider'
import { handleSearch, onChangePagination } from '../../utils/CommonFunctions'
import PaginationLayout from '../common/PaginationLayout'
import "../../styles/style.scss"
import UserTableDetails from './UserTableDetails'
import moment from 'moment'
import DownloadFileModal from '../common/download-file-modal'
import { Pagination } from 'antd'

const UserListing = () => {

    const filterArray = [
        { id: '', name: 'All' },
        { id: '2', name: 'Active Users' },
        { id: '3', name: 'Deactive Users' },
        { id: '4', name: 'Blocked Users' },
    ]

    const navigate = useNavigate();
    const location = useLocation();
    const { authState, downloadCSV } = useContext(GlobalContext);
    const [dataLoading, setDataLoading] = useState(false)

    henceforthApi.setToken(authState.access_token);
    const [pageData, setpageData] = useState<any>({
        data: [],
        totalCount: 0
    })
    const newParam = new URLSearchParams(location.search);

    const match: any = useMatch("/users/:page")
    let limit = 10;

    const initialise = async () => {
        setDataLoading(true)
        try {
            let apires = await henceforthApi.User.pagination(
                newParam.toString(),
                Number(match.params.page),
                10
            )
            // toast.success(apires.message)
            setpageData({
                data: apires.data.data,
                totalCount: apires.data.total
            })
        } catch (error) {
            handleError(error)
        } finally {
            setDataLoading(false)
        }
    }
    useEffect(() => {
        initialise()
        // eslint-disable-next-line
    }, [match.params.page, newParam.get("search"), newParam.get("filter")])


    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.User.export(startDate, endDate)
            const data = apiRes.data
            console.log(data);
            downloadCSV(data)
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="d-flex justify-content-between align-items-center border-bottom py-3 px-4 bg-white">
                                <div className="">
                                    <h3 className="fw-semibold">User</h3>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item ">
                                                <Link className="text-decoration-none text-black" to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item ">
                                                {/* <h2 className="fw-semibold text-secondary">User</h2> */}
                                                <Link className="not-allowed text-decoration-none text-secondary" to="#">User list</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            {/* <h3 className="fw-semibold p-4 bg-white border-bottom">User</h3> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}

            <section className='product-listing'>
                <div className="container-fluid">
                    {/* search-filter-export */}
                    <div className='common-card mb-4 border-0 card-spacing px-3'>

                        {/* table  */}
                        <div className="row bg-white border rounded mx-0">
                            <div className='col-12 px-0'>
                                <div className='d-flex justify-content-between align-items-center border-bottom py-3 px-4'>
                                    <h6 className=''>List of All User&apos;s </h6>
                                    <div className='d-flex align-items-center gap-3'>

                                        {/* dropdown */}
                                        <div className="dropdown">
                                            <button className="btn shadow-none dropdown-toggle border py-1 px-3 fw-500 fs-12 rounded " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {newParam.has('filter') ? filterArray.find((res) => res.id === newParam.get('filter'))?.name : 'All'}&nbsp;
                                            </button>
                                            <ul className="dropdown-menu py-2 overflow-hidden">
                                                {filterArray.map((res) => <li onClick={() => handleSearch('filter', res.id, location, navigate, newParam)}><button className="dropdown-item">{res.name}</button></li>)}
                                            </ul>
                                        </div>
                                        <div className='d-flex gap-3 justify-content-md-end '>
                                            <div className='download-export-box text-center'>
                                                {/* <label className='mb-1 form-label fw-semibold'>Export File</label> */}
                                                <div className="export-button border rounded">
                                                    <button className="btn shadow-none py-1 px-3 fs-12 border-0" type="button" data-bs-toggle="modal" data-bs-target="#fileDownloadModal"> <i className='fa fa-cloud-download me-2'></i>
                                                        <span>.csv</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 '>
                                <div className='position-relative px-2 py-3'>
                                    <input type="search" value={newParam.has('search') ? newParam.get('search') as string : ''} className="form-control rounded-0 ps-4 " name='search' placeholder="Search by Name, Email"
                                        onChange={(e: any) => handleSearch(e.target.name, e.target.value, location, navigate, newParam)}
                                    />
                                </div>
                            </div>
                            {/* cleaway-table */}
                            <div className="col-12 px-0">
                                <UserTableDetails
                                    limit={limit}
                                    dataLoading={dataLoading}
                                    tableData={pageData.data}
                                    SerialNo={Number(match?.params?.page)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <Pagination /> */}

                    {/* <PaginationLayout
                        count={pageData.totalCount}
                        data={pageData.data}
                        page={Number(match?.params.page)}
                        limit={limit}
                    /> */}
                    <div className="text-center mt-2">
                        <Pagination current={Number(match?.params.page)} className='text-primary' onChange={(e: any) => onChangePagination(e, location, navigate, newParam)} total={pageData.totalCount} showSizeChanger={false} />
                    </div>
                </div>
            </section>

            <DownloadFileModal exportData={exportData} />
        </>
    )
}
export default UserListing;

