import { Pagination } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import userImage from '../../assets/image/user-image.png';
import { GlobalContext, handleError } from '../../context/Provider';
import { handleSearch, onChangePagination } from '../../utils/CommonFunctions';
import henceforthApi from '../../utils/henceforthApi';
import PaginationLayout from '../common/PaginationLayout';
import DonationTableDetails from './DonationTableDetails';

const Donations = () => {
    const filterArray = [
        { id: '', name: 'All' },
        { id: '2', name: 'Succeed' },
        { id: '3', name: 'Failed' },
        { id: '4', name: 'Incomplete' },
    ]

    const navigate = useNavigate();
    const location = useLocation();
    const { authState } = useContext(GlobalContext);
    const [dataLoading, setDataLoading] = React.useState(false)

    henceforthApi.setToken(authState.access_token);
    const [pageData, setpageData] = React.useState<any>({
        data: [],
        totalCount: 0
    })
    const newParam = new URLSearchParams(location.search);

    const match: any = useMatch("/donations/:page")
    let limit = 10;

    const initialise = async () => {
        setDataLoading(true)
        try {
            let apires = await henceforthApi.Donation.pagination(
                newParam.toString(),
                Number(match.params.page)
            )
            // toast.success(apires.message)
            setpageData({
                data: apires.data.data,
                totalCount: apires.data.total
            })
        } catch (error) {
            handleError(error)
        } finally {
            setDataLoading(false)
        }
    }
    React.useEffect(() => {
        initialise()
        // eslint-disable-next-line
    }, [match.params.page, newParam.get("search"), newParam.get("filter")])


    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Donation.export(startDate, endDate)
            const data = apiRes.data.data
            const rows = [
                [
                    "Sr.no",
                    "Name",
                    "Email",
                    "Phone Number",
                    "Status"
                ],
            ];
            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    rows.push([
                        index + 1,
                        res.name,
                        res.email,
                        res.phone_number,
                        res.status,
                    ])
                })
            }
            console.log(rows);
            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `user_${moment().valueOf()}.csv`);
            document.body.appendChild(link);
            link.click();
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
            // debugger
        } catch (error) {
            handleError(error)
        }
    }


    return (
        <>
            {/* Donation-section */}
            <section className='donation-section py-0'>
                <div className="conatiner-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="header fw-semibold p-4 bg-white border-bottom">
                                <h3>Donations</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Donation-listing */}
                <div className="container-fluid py-4 px-4">
                    <div className="common-card bg-white border rounded">
                        <div className="row">
                            <div className="col-12">
                                <div className="title px-4 py-3 border-bottom d-flex justify-content-between align-items-center">
                                    <h5>List of All Donations</h5>
                                    {/* <div className="dropdown">
                                        <button className="btn shadow-none dropdown-toggle border py-1 px-3 fw-500 fs-12 rounded " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {newParam.has('filter') ? filterArray.find((res) => res.id === newParam.get('filter'))?.name : 'All'}&nbsp;
                                        </button>
                                        <ul className="dropdown-menu py-2 overflow-hidden">
                                            {filterArray.map((res) => <li onClick={() => handleSearch('filter', res.id, location, navigate, newParam)}><button className="dropdown-item">{res.name}</button></li>)}
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className='position-relative px-2 py-3'>
                                    <input type="search" value={newParam.has('search') ? newParam.get('search') as string : ''} className="form-control rounded-0 ps-4 " name='search' placeholder="Search by Name, Email & Transaction ID"
                                        onChange={(e: any) => handleSearch(e.target.name, e.target.value, location, navigate, newParam)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <DonationTableDetails
                                    limit={limit}
                                    dataLoading={dataLoading}
                                    tableData={pageData.data}
                                    SerialNo={Number(match?.params?.page)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <PaginationLayout
                        count={pageData.totalCount}
                        data={pageData.data}
                        page={Number(match?.params.page)}
                        limit={limit}
                    /> */}
                    <div className="text-center mt-2">
                        <Pagination current={Number(match?.params.page)} className='text-primary' onChange={(e: any) => onChangePagination(e, location, navigate, newParam)} total={pageData.totalCount} showSizeChanger={false} />
                    </div>
                </div>
            </section>

        </>
    )
}

export default Donations;
