import { Link, useMatch } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi';
import Spinner from '../common/BootstrapCompo';
import Swal from 'sweetalert2';
import Placeholder from '../common/Placeholder';
import { goBack } from '../../utils/CommonFunctions';
import { toast } from 'react-toastify';

const ViewVehicle = () => {
    const match = useMatch("vehicle/:_id/view")
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)


    const [state, setState] = useState<any>({
        data: {
            id: "",
            car: "",
            model: "",
            model_year: "",
            type: "",
            is_deactive: false,
        }
    })


    const initialise = async () => {
        if (!loaded) {
            setLoading(true)
        }
        try {
            const apiRes = await henceforthApi.Vehicles.get(match?.params?._id as string)
            setState((state: any) => { return { ...state, ...apiRes.data } })
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false)
            setState((state: any) => {
                return {
                    ...state,
                    blockLoading: false,
                    deleteLoading: false

                }
            })
        }
    }

    const handleVehicleEvents = () => {
        Swal.fire({
            title: `Are you sure you want to Delete <br /> <span>Vehicle ${state?.car}</span> ? `,
            showCancelButton: true,
            confirmButtonColor: "#FE0002",
            cancelButtonColor: "transparent",
            confirmButtonText: "Yes, Confirm",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                setLoading(true)
                try {
                    let res = await henceforthApi.Vehicles.delete(match?.params._id as string)
                    toast.success(res.message)
                    goBack()
                    // if (value === 'Block') return await henceforthApi.Staff.staffBlock(match?.params?._id as string), initialise()
                } catch (error) {
                    handleError(error)
                }
                finally {
                    setLoading(false)
                }
            }
        })
    }

    useEffect(() => {
        setLoaded(true)
        initialise()
    }, [])

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* <h2 className='  p-4 bg-white border-bottom'>Vehicles</h2> */}
                            <div className="d-flex justify-content-between align-items-center border-bottom py-3 px-4 bg-white">
                                <div className="">
                                    <h3 className="fw-semibold">Vehicles</h3>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item ">
                                                <Link className="text-decoration-none text-black" to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item ">
                                                {/* <h2 className="fw-semibold text-secondary">User</h2> */}
                                                <Link className="not-allowed text-decoration-none text-secondary" to="#">Vehicle Details</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='change-password'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                {/* Title  */}
                                <div className="common-card border bg-white rounded">
                                    <div className="common-card-title py-3 px-4 border-bottom">
                                        <h5>Vehicles Detail</h5>
                                    </div>
                                    {/* Profile  */}
                                    {loading ? <Placeholder length={6} /> : <div className="common-card-content">
                                        {/* Profile Detail  */}
                                        <div className="profile-details py-4 px-4">
                                            <div className="d-flex gap-2 gap-lg-5 flex-wrap ">
                                                <div className='d-flex gap-3'>
                                                    <label htmlFor="staffName" className='text-gray'>Vehicle Name :</label>
                                                    <p>{state?.car}</p>
                                                </div>
                                                <div className='staff-name d-flex gap-3'>
                                                    <label className='text-gray'>Vehicle Type :</label>
                                                    <p>{state?.type}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-2 gap-lg-5 flex-wrap mt-3">
                                                <div className='staff-name d-flex gap-3'>
                                                    <label className='text-gray'>Vehicle Model :</label>
                                                    <p>{state?.model}</p>
                                                </div>
                                                <div className='staff-name d-flex gap-3'>
                                                    <label className='text-gray'>Model Year:</label>
                                                    <p>{state?.model_year}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* button  */}
                                        <div className="profile-button px-4 pb-4">
                                            <ul className='list-unstyled d-flex gap-2 flex-wrap flex-lg-nowrap'>
                                                <li> <Link to={`/vehicle/${match?.params?._id}/edit`} className='btn btn-primary px-3 py-2'>Edit</Link >
                                                </li >
                                                <li><button type='button' className='btn btn-outline-danger' onClick={handleVehicleEvents} disabled={state.deleteLoading} >{state.deleteLoading ? <Spinner /> : "Delete"}</button></li>
                                                {/* <li>
                                                    < button type='button' onClick={() => handleStaffEvents('Block', state.is_block === 1 ? 'Unblock' : 'Block')} className='btn btn-theme px-3 py-2' disabled={state.blockLoading}>{state.blockLoading ? <Spinner /> : (state.is_block === 1 ? 'Unblock' : 'Block')}</button>
                                                </li> */}
                                                {/* <li>
                                                    <Link to={`/staff/${match?.params._id}/reset-password`}>
                                                        < button type='button' className='btn btn-theme text-black px-2 py-2 text-nowrap'>Reset Password</button>
                                                    </Link>
                                                </li> */}
                                            </ul >
                                        </div >
                                    </div >}
                                </div >
                            </div >
                        </div >
                    </div >
                </section >
            </div >
        </>
    )
}
export default ViewVehicle;