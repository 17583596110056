import _superagent from 'superagent';
const SuperagentPromise = require('superagent-promise');
const superagent = SuperagentPromise(_superagent, global.Promise);
const checkStartApi = (window.origin.includes('staging') || window.origin.includes('localhost'))
const API_ROOT = `https://${checkStartApi ? 'staging.' : ''}backend.clearwaywatch.com/api/`;
// const API_ROOT = `https://backend.clearwaywatch.com/api/`;
const BUCKET_ROOT = `https://clearwaystaging.s3.ap-southeast-2.amazonaws.com/`;

const API_FILE_ROOT_MEDIUM = `${BUCKET_ROOT}medium/`;
const API_FILE_ROOT_ORIGINAL = `${BUCKET_ROOT}original/`;
// const API_FILE_ROOT_SMALL = `${BUCKET_ROOT}smallthumbnail/`;
const API_FILE_ROOT_SMALL = `${BUCKET_ROOT}thumbnail/`;
const API_FILE_ROOT_AUDIO = `${BUCKET_ROOT}audio/`;
const API_FILE_ROOT_VIDEO = `${BUCKET_ROOT}video/`;
// const API_FILE_ROOT_DOCUMENTS = `${BUCKET_ROOT}documents/`;

const encode = encodeURIComponent;
const responseBody = (res: any) => res.body;

let token: any = null;
const tokenPlugin = (req: any) => {
  if (token) {
    req.set('authorization', `Bearer ${token}`);
    // req.set('token', token);
  }
}

const requests = {
  del: (url: string) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url: string) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url: string, body: any) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  patch: (url: string, body: any) =>
    superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url: string, body: any) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  file: (url: string, key: string, file: any) =>
    superagent.post(`${API_ROOT}${url}`).attach(key, file).use(tokenPlugin).then(responseBody),
  postWfile: (url: string, body: any, key: string, file: any) =>
    superagent.post(`${API_ROOT}${url}`).field(body).attach(key, file).use(tokenPlugin).then(responseBody)
};

const Auth = {
  login: (info: any) =>
    requests.post('admin/login', info),
  loginAsUser: (info: any) =>
    requests.post('Admin/users/login_as_user', info),
  signUp: (info: any) =>
    requests.post('User/signup', info),
  signUpAsCreater: (info: any) =>
    requests.post('User/sigup_as_creater', info),
  resetPassword: (info: any) =>
    requests.post('User/forgot_password', info),
  logout: () =>
    requests.put('admin/logout', {}),
  checkOtp: (info: any) =>
    requests.post('check-email-otp', info),
  resendOtp: (info: any) =>
    requests.post('User/resend_otp', info),
  resendOtpForForgotPassword: (info: any) =>
    requests.post('User/forgot_password/resend_otp', info),
  emailVerification: (info: any) =>
    requests.post('User/email_verification', info),
  ageVerification: (info: any) =>
    requests.post('User/age_verification', info),
  connetWallet: (info: any) =>
    requests.post('User/connect/wallet', info),
  connectSocialAccount: (info: any) =>
    requests.post('User/connect/social_account', info),
  changePassword: (info: any) =>
    requests.put('admin/change-password', info),
  forgotChangePassword: (info: any) =>
    requests.post('User/forgot_password/set_password', info),
  profile: () =>
    requests.get(`admin/profile-detail`),
  edit: (info: any) =>
    requests.post('admin/profile-update', info),
  editImage: (info: any, key: string, file: any) =>
    requests.postWfile('admin/profile-update', info, key, file),
  socialLogin: (info: any) =>
    requests.post('User/social_login', info),
  verifyOtp: (info: any) =>
    requests.post('User/forgot_password/verify_otp', info),
  resendPhoneOtp: (info: any) =>
    requests.post('User/resend/phone_no/otp', info),
  verifyPhone: (info: any) =>
    requests.post('User/verify/phone_no', info),
};
const Common = {
  uploadFile: (key: string, file: any) =>
    requests.file(`admin/image-upload`, key, file),
  contactus: (info: any) =>
    requests.post(`User/contact_us`, info),
  nested: () =>
    requests.get(`User/nested`),
  notification: (pagination: number, limit: number) =>
    requests.get(`user/notification`),
  payments: () =>
    requests.get(`user/payments`),
  paymentsecurity: () =>
    requests.get(`user/paymentsecurity`),
  returnpolicy: () =>
    requests.get(`user/returnpolicy`),
  shipping: () =>
    requests.get(`user/shipping`),
  faq: () =>
    requests.get(`Product/faqs?language=ENGLISH`),
  content: (type: string) =>
    requests.get(`User/list_content?language=ENGLISH&type=${type}`),
  checkDelivery: (id: string, lat: string, lng: string) =>
    requests.get(`Product/check/delivery?product_id=${id}&lat=${lat}&lng=${lng}&language=ENGLISH`),
  timer: () =>
    requests.get(`Homepage/user/deals_day/timer`),
  privacyPolicy: (info: any) =>
    requests.post(`admin/privacy-policy`, info),
  getPrivacyPolicy: () =>
    requests.get(`admin/privacy-policy`),
  termscondition: (info: any) =>
    requests.post(`admin/terms-and-condition`, info),
  getTermscondition: () =>
    requests.get(`admin/terms-and-condition`),
  aboutUs: (info: any) =>
    requests.post(`admin/about-us`, info),
  getAboutUs: () =>
    requests.get(`admin/about-us`),
  backupDb: (bodyData: any) =>
    requests.post("admin/create-database", bodyData),
  downloadDb: (bodyData: any) =>
    requests.get(`admin/download-database/${bodyData}`),

}

const Administrator = {
  getStaffMemberDetail: (id: any) =>
    requests.get(`Admin/staff_members/details?_id=${id}&language=ENGLISH`),

  addStaffMembers: (values: any) => {
    return requests.post(`Seller/products`, values);
  },
  blockOrDeleteStaff: (values: any) => requests.put("Admin/staff_members/block_delete", values),

  getStaffMembers: (values: any) =>
    requests.get(
      `Admin/staff_members?language=${values.language}&search=${values.search}&pagination=${values.page}&limit=${values.limit}`
    ),

  getPagnation: (page: any, limit: any, filter: any) =>
    requests.get(
      `admin/administration?page=${page}&limit=${limit ? limit : 10}${filter ? filter : ""
      }`
    ),
  changeRole: (info: any) => requests.put("admin/role", info),

  changePassword: (values: any) =>
    requests.put("Admin/change_password", {
      old_password: values.oldPass,
      new_password: values.newPass,
      language: values.language,
    }),
  editImgProfile: (info: any) => requests.put('Admin/profile', info),

  editProfile: (values: any) =>
    requests.put(`Admin/profile`, values),

  sendNotifications: (values: any) => requests.post(`admin/mail-broadcast`, values),
  pushNotifications: (values: any) => requests.post(`admin/push-broadcast`, values),
};

const Graph = {
  salegraph: (search: any) => requests.get(`admin/graph${search ? `?filter=${search}` : ""}`)
}

const Content = {
  AboutusList: () => requests.get(`Admin/content/listing?language=ENGLISH`)
}

const ContactUs = {
  pagination: (page: any, search: any) => requests.get(`Admin/contact_us/listing?language=ENGLISH${search ? `&${search}` : ""}&pagination=${Number(page)}&limit=6`),
  get: (values: any) => requests.get(`Admin/contact_us?language=${values.language}&search=${values.search}&pagination=${values.page}&limit=${values.limit}`),
  delete: (values: any) => requests.put(`Admin/contact_us/resolve_delete`, values),
  resolve: (values: any) => requests.put(`Admin/contact_us/resolve_delete`, values),
};
const Faq = {
  add: (values: any) => requests.post(`Admin/faqs`, values),
  edit: (values: any) => requests.post(`Admin/faqs`, values),
  get: () => requests.get(`Admin/faqs`),
  delete: (id: any) => requests.del(`Admin/faqs/delete/${id}`),

};
const Profile = {
  edit: (info: any) =>
    requests.put('User/profile', info),
  get: () =>
    requests.get(`User/profile?language=ENGLISH`),
  fcmToken: (fcm_token: string) =>
    requests.put('User/fcm', {
      device_type: "Web",
      fcm_token,
      language: "ENGLISH"
    }),
};

const Dashboard = {
  count: () => requests.get('admin/dashboard'),
}

const Notification = {
  getNotification: (pagination: any, limit: any) => requests.get(`Admin/notifications?language=ENGLISH&pagination=${pagination}&limit=${limit}`),
  markAllAsRead: (value: any) => requests.put(`Admin/notifications?language=ENGLISH`, value),
  clearAllNotification: (value: any) => requests.put(`Admin/notifications/clear?language=ENGLISH`, value),
  markAsRead: (info: any, id: string) => requests.put(`Admin/notifications/read/${id}?language=ENGLISH`, info),

  orderDetails: (_id: any) => requests.get(`Admin/order/detail/${_id}?language=ENGLISH`)

}

const Staff = {
  pagination: (q: any, pagination: number) => requests.get(`admin/staff?page=${pagination}${q ? `&${q}` : ""}`),
  add: (values: any) => requests.post(`admin/staff`, values),
  edit: (id: any, values: any) => requests.put(`admin/staff/${id}`, values),
  get: (_id: string) => requests.get(`admin/staff-detail/${_id}`),
  delete: (_id: string) => requests.del(`admin/staff/${_id}`),
  resetPassword: (_id: string, values: any) => requests.put(`admin/staff/reset-password/${_id}`, values),
  staffBlock: (id: string) => requests.put(`admin/staff-block/${id}`, {}),
  export: (start_date: number, end_date: number) => requests.get(`admin/staff_members/listing?language=ENGLISH&start_date=${start_date}&end_date=${end_date}`),
};


const Vehicles = {
  pagination: (q: any, pagination: number) => requests.get(`admin/vehicle?page=${pagination}${q ? `&${q}` : ""}`),
  add: (values: any) => requests.post(`admin/vehicle`, values),
  edit: (id: any, values: any) => requests.put(`admin/vehicle/${id}`, values),
  get: (_id: string) => requests.get(`admin/vehicle/${_id}`),
  delete: (_id: string) => requests.del(`admin/vehicle/${_id}`),
  staffBlock: (id: string) => requests.put(`admin/staff-block/${id}`, {}),
  export: (start_date: number, end_date: number) => requests.get(`admin/staff_members/listing?language=ENGLISH&start_date=${start_date}&end_date=${end_date}`),
};
const Donation = {
  pagination: (q: any, pagination: number) => requests.get(`admin/donation?page=${pagination}${q ? `&${q}` : ""}`),
  export: (start_date: number, end_date: number) => requests.get(`admin/donation?language=ENGLISH${start_date ? `&start_date=${start_date}` : ""}${end_date ? `&end_date=${end_date}` : ""}`),
};

const User = {
  pagination: (q: any, pagination: number, limit: any) => requests.get(`admin/users?page=${pagination}${limit ? `&limit=${limit}` : ""}${q ? `&${q}` : ""}`),
  get: (id: string) => requests.get(`admin/users/${id}`),
  delete: (id: string) => requests.del(`admin/users/${id}`),
  blockUnblock: (id: string) => requests.put(`admin/user-block/${id}`, {}),
  activeDeactive: (id: string) => requests.put(`admin/user-active/${id}`, {}),
  export: (start_date: number, end_date: number) => requests.get(`admin/user-export?language=ENGLISH${start_date ? `&start_date=${start_date}` : ""}${end_date ? `&end_date=${end_date}` : ""}`),
  notificationUser: (search: string) =>
    requests.get(
      `admin/users?language=ENGLISH${search ? `&search=${search}` : ``}`
    ),
}
const Poll = {
  pagination: (q: any, pagination: number) => requests.get(`admin/polls?page=${pagination}${q ? `&${q}` : ""}`),
  get: (id: string) => requests.get(`admin/polls/${id}`),
  getVotes: (id: string) => requests.get(`admin/view-votes/${id}`),
  edit: (id: any, values: any) => requests.put(`admin/polls/${id}`, values),
  add: (values: any) => requests.post(`admin/polls`, values),
  delete: (id: string) => requests.del(`admin/polls/${id}`),
  export: (start_date: number, end_date: number) => requests.get(`admin/poll-export?language=ENGLISH${start_date ? `&start_date=${start_date}` : ""}${end_date ? `&end_date=${end_date}` : ""}`),
}

const ClearWayaZone = {
  add: (info: any) => requests.post('admin/clearway', info),
  edit: (id: any, info: any) => requests.put(`admin/clearway/${id}`, info),
  get: (id: string) => requests.get(`admin/clearway/${id}`),
  detail: (id: string) => requests.get(`admin/clearway/${id}`),
  delete: (id: string) => requests.del(`admin/clearway/${id}`),
  pagination: (pagination: any, limit: any, q: any) => requests.get(`admin/clearway${pagination ? `?page=${pagination}` : ''}${limit ? `&limit=${limit}` : ''}${q ? `&${q}` : ''}`),
  export: (start_date: any, end_date: any) =>
    requests.get(
      `admin/clearway-export?language=ENGLISH${start_date ? `&start_date=${start_date}` : ""}${end_date ? `&end_date=${end_date}` : ""}`
    ),
}
const FILES = {
  audio: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_AUDIO}${filename}`,
  video: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_VIDEO}${filename}`,
  imageOriginal: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_ORIGINAL}${filename}`,
  imageMedium: (filename: string) => filename?.startsWith('http') ? filename : `${API_FILE_ROOT_MEDIUM}${filename}`,
  imageSmall: (filename: string) => filename?.startsWith('http') ? filename : filename.startsWith('user') ? `${BUCKET_ROOT}${filename}` : `${API_FILE_ROOT_SMALL}${filename}`,
}

const henceforthApi = {
  token,
  Auth,
  API_FILE_ROOT_ORIGINAL,
  API_FILE_ROOT_SMALL,
  Poll,
  Common,
  Content,
  ContactUs,
  Faq,
  Vehicles,
  Profile,
  ClearWayaZone,
  Staff,
  Dashboard,
  Graph,
  User,
  Donation,
  API_ROOT,
  Notification,
  FILES,
  Administrator,
  encode,
  setToken: (_token?: string) => { token = _token; }
};

export default henceforthApi