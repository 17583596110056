import { Link } from "react-router-dom"
import Placeholder from "../common/Placeholder"
import view from '../../assets/icons/view_blue.svg'
import henceforthApi from "../../utils/henceforthApi"
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';

type propsType = {
    limit: number
    dataLoading: boolean
    tableData: any
    SerialNo: number
}

const UserTableDetails = (props: propsType) => {
    const { limit, dataLoading, tableData, SerialNo } = props
    return (
        <>
            <div className='table-responsive px-0 Recent-clearwayzone'>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    {dataLoading ? <tbody> <tr><td colSpan={6}><Placeholder length={10} /></td> </tr></tbody> : <tbody>
                        {Array.isArray(tableData) && tableData.length ? tableData.map((res: any, index: any) =>
                            <tr key={res.id}>
                                <td>
                                    {SerialNo === 0
                                        ? index + 1
                                        : (SerialNo - 1) * limit + (index + 1)}.
                                </td>
                                <td>
                                    <div className="d-flex gap-2 align-items-center">
                                        {<img src={res?.profile_pic ? henceforthApi.FILES.imageSmall(res?.profile_pic) : defaultUserImg} alt="img" className='me-1 staff-table-img' />}
                                        <span>{res.name ? `${res.name} ` : `Not Available`}</span>
                                    </div>
                                </td>
                                <td>{res.email ? `${res.email}` : 'Not Available'}</td>
                                <td>{res.phone_number ? `${res.country_code} ${res.phone_number}` : 'Not Available'}</td>
                                <td>
                                    <span className={res.is_block === 1 || (res.is_deactive
                                        === 1 && res.is_block === 1) ? 'text-black' : res.is_deactive === 1 ? 'closed-status' : ''}>{res.is_block === 1 || (res.is_deactive
                                            === 1 && res.is_block === 1) ? 'Blocked' : res.is_deactive === 1 ? 'Deactive' : ''}</span>

                                    {res.is_deactive
                                        !== 1 && res.is_block !== 1 && <span className='draft-status'>Active</span>}
                                </td>
                                <td><div className="btn-group gap-2">
                                    <Link to={`/user/${res.id}`} className='btn view-btn border'>
                                        <img src={view} className='img-fluid me-2' alt='image' />
                                        <span className='align-middle'>View</span>
                                    </Link>
                                </div>
                                </td>
                            </tr>)
                            : <tr><td colSpan={9} className="text-center">No data found</td>
                            </tr>
                        }
                    </tbody>}
                </table>
            </div>
        </>
    )
}
export default UserTableDetails