import { toast } from "react-toastify";
import { useContext, useState } from 'react';
import loginSuccess from "../../context/actions/auth/loginSuccess";
import henceforthApi from '../../utils/henceforthApi';
import { GlobalContext, handleError } from '../../context/Provider';
import Spinner from '../common/BootstrapCompo';
import logo from '../../assets/icons/logo.svg'
import lock from '../../assets/lock.svg'
import email from '../../assets/email.svg'
import henceforthValidations from '../../utils/henceforthValidations'
const SignIn = ({ logOutNow }: any) => {

    const { authDispatch } = useContext(GlobalContext);
    const [firstEye, setFirstEye] = useState(true);
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [state, setState] = useState({
        email: "",
        password: "",
        loading: false,
    });
    const handleInput = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "email") setEmailError('')
        if (name === "password") setPasswordError('')
        setState({
            ...state,
            [name]: value,
        });

    };
    const onSubmitForm = async (e: any) => {
        e.preventDefault();
        if (!state.email && !state.password) {
            setEmailError("Email is required");
            setPasswordError('Password is required')
            return
        }

        if (!state.email.trim()) return setEmailError('Email is required')
        if (!henceforthValidations.email(state.email)) return setEmailError("Please fill valid email")
        if (!state.password) return setPasswordError("Password is required");
        if (!state.password.trim()) return setPasswordError("Please fill valid password");
        setState({
            ...state,
            loading: true
        });
        const { email, password } = state;
        let items = {
            email: email,
            password: password,
            language: "ENGLISH",
        };
        try {
            let res = await henceforthApi.Auth.login(items)
            // console.log(res)
            loginSuccess(res.data)(authDispatch);
            toast.success("Login Successful");
        } catch (err: any) {
            let response = err.response.body;
            let error = response ? response.error_description : "Server error";
            handleError(err)
            if (error.error) {
                if (String(error.error).includes("unauthorized")) {
                    logOutNow();

                }
            }
        } finally {
            setState({
                ...state,
                loading: false,
            });
        }
    }

    return (
        <>
            <section className='auth-pages'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-7 col-sm-10">
                            <div className="auth-page-card mb-4">
                                {/* logo */}
                                <div className='auth-logo-box text-center mb-3'>
                                    <img src={logo} alt="img" className="img-fluid" />
                                </div>
                                {/* title  */}
                                <div className='auth-page-card-title text-center my-4'>
                                    <h2 className='mb-2 fw-bold'>Welcome to <span className="text-primary">Clearway Watch</span></h2>

                                </div>
                                {/* form  */}
                                <form onSubmit={onSubmitForm} className='row justify-content-center'>
                                    {/* Username  */}
                                    <div className="col-sm-11">
                                        <div className=' mb-3 '>
                                            <div className="form-fields-box d-flex align-items-center is-invalid">
                                                <span className='input-group-text'>
                                                    <img src={email} className='img-fluid' alt='image' />
                                                </span>
                                                <input type="text"
                                                    name="email"
                                                    value={state.email}
                                                    onChange={handleInput}
                                                    className={`form-control px-1 rounded-0 border-0 ${emailError ? "is-invalid" : ""}`} placeholder="Email" />
                                            </div>
                                            {/* error msg  */}
                                            <div className={`${emailError ? "invalid-feedback" : ""}`}>
                                                {emailError}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Password  */}
                                    <div className="col-sm-11">
                                        <div className=' mb-5'>
                                            <div className={`form-fields-box input-group is-invalid `}>
                                                <div className="input-group-append ">
                                                    <span className='input-group-text'>
                                                        <img src={lock} className='img-fluid' alt='image' />
                                                    </span>
                                                </div>
                                                <input type={firstEye ? "password" : "text"}
                                                    name="password"
                                                    onChange={handleInput}
                                                    value={state.password}
                                                    className={`form-control px-1 border-0 ${passwordError ? "is-invalid" : " "}`} placeholder="Password" />
                                                <div className="input-group-append ">
                                                    <span className={`input-group-text ${passwordError ? `border-0` : ""} group-btn-span`} onClick={() => setFirstEye(!firstEye)}> <i
                                                        className={`fa ${!firstEye ? `fa-eye-slash ${passwordError ? `text-danger` : `text-secondary`} ` : `fa-eye ${passwordError ? `text-danger` : `text-secondary`}`}`}

                                                        aria-hidden="true"
                                                    ></i></span>
                                                </div>
                                            </div>
                                            {/* error msg  */}
                                            <div className={`${passwordError ? "invalid-feedback" : ""}`}>
                                                {passwordError}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Signin button  */}
                                    <div className="col-sm-11">
                                        <div className='signin-button-box btn-theme'>
                                            <button type='submit' className='btn btn-primary w-100 ' disabled={state.loading} >{state.loading ? <Spinner /> : "Log In"}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );
};

export default SignIn;
