import { Link, useLocation } from 'react-router-dom';
import henceforthApi from '../../utils/henceforthApi';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/Provider';
import clearwayzoneblack from '../../assets/icons/clearwayzone_black.svg'
import clearwayzonewhite from '../../assets/icons/clearwayzone_white.svg'
import pollsblack from '../../assets/icons/poll_black.svg'
import pollswhite from '../../assets/icons/poll_white.svg'
import donationBlack from '../../assets/icons/donation_black.svg'
import donationWhite from '../../assets/icons/donation_white.svg'
import vehicleBlack from '../../assets/icons/vehicle_black.svg';
import vehicleWhite from '../../assets/icons/vehicle_white.svg';
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';

import Swal from 'sweetalert2';
interface stryleCategory {
    _id: string
    name: string
}
const TheSideBar = (props: any) => {
    let clearway = 'clearway'
    let poll = 'poll'
    let Privacy = 'Privacy'
    let Terms = 'Terms'
    let donations = 'donations'
    let vehicle = 'vehicle'
    const { authState, logOutNow } = useContext(GlobalContext);
    const roles = (Array.isArray(authState.access_role_name) ? authState.access_role_name : [])
    const location = useLocation()
    henceforthApi.setToken(authState.access_token);
    const [hover, setHover] = useState('')
    const onHover = (hover: string) => {
        if (hover === clearway) {
            setHover(clearway)
        }
        else if (hover === poll) {
            setHover(poll)
        }
        else if (hover === Privacy) {
            setHover(Privacy)
        }
        else if (hover === Terms) {
            setHover(Terms)
        }
        else if (hover === donations) {
            setHover(donations)
        }
        else if (hover === vehicle) {
            setHover(vehicle)
        }

    }

    const logoutUser = async () => {
        Swal.fire({
            title: "Are you sure you want to logout?",

            showCancelButton: true,
            confirmButtonColor: "#FE0002",
            cancelButtonColor: "transparent",
            confirmButtonText: "Yes, Confirm",
        }).then(async (result: any) => {
            if (result.isConfirmed) return logOutNow()
        })
    }

    return (
        <>
            <div>
                {/* sidebar Header  */}
                <div className="sidebar-header">

                    <div className='desktop-screen pt-4 border-bottom'>
                        {/* user detail  */}
                        <div className="user-detail-box">
                            <img src={authState.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${authState.profile_pic}` : defaultUserImg} className='img-fluid rounded-circle' alt="img" />
                        </div>
                        <h6 className='user-name mt-3'>John</h6>
                        <div className="dropdown-center">
                            {props.handled ? <button className="btn shadow-none border-0 dropdown-toggle p-0 fw-bold mt-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {authState.name ? `${authState.name}` : "John Doe "}
                            </button> : <span className='text-muted'>{authState.name ? `${authState.name}` : "John Doe "}</span>}
                            <ul className="dropdown-menu pt-2 overflow-hidden">
                                <li><Link className="dropdown-item" to="/profile">Profile</Link></li>

                                {(authState.role_id === 1) && <li><Link className="dropdown-item" to="/staffs/1">Staff Management</Link></li>}

                                <li><Link className="dropdown-item" to="/change-password">Change Password</Link></li>

                                <li className='divider'><hr className='mb-0' /></li>
                                <li><Link className="dropdown-item" to="" onClick={logoutUser}>Logout</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/* navigation bar  */}
                <div className='navigation-bar'>
                    <div className="accordion" id="Navigation-bar">
                        {/* Dashboard */}
                        {(roles.includes('1') || authState.role_id === 1) ?
                            <div className={`accordion-item rounded-0 ${location.pathname === '/' ? 'link-active' : ''}`} onClick={() => setHover('')}>
                                <h6 className="accordion-header">
                                    <Link to="/" className={`accordion-button shadow-none active d-flex align-items-center collapsed text-decoration-none single-link`}>
                                        <i className='fa fa-th-large me-3 fs-5'></i> {props.handled && <span>Dashboard</span>}
                                    </Link>
                                </h6>
                            </div> : ""}

                        {(roles.includes('2') || authState.role_id === 1) &&
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/user') ? 'link-active' : ''}`} onClick={() => setHover('')}>
                                <h6 className="accordion-header">
                                    <Link to="/users/1" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <i className='fa fa-users me-3 fs-5'></i> {props.handled && <span>User</span>}
                                    </Link>
                                </h6>
                            </div>}

                        {/* Products  */}
                        {(roles.includes('3') || authState.role_id === 1) &&
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/clearwayzone') ? 'link-active' : ''}`} onMouseOver={() => onHover(clearway)} onMouseOut={() => setHover('')}>
                                <h6 className="accordion-header">
                                    <Link to="/clearwayzone/1" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <img src={location.pathname.startsWith('/clearwayzone') || hover === clearway ? clearwayzonewhite : clearwayzoneblack} className='me-3' alt='name' /> {props.handled && <span>Clearwayzone</span>}
                                    </Link>
                                </h6>
                            </div>}
                        {/* Order  */}
                        {(roles.includes('4') || authState.role_id === 1) &&
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/poll') ? 'link-active' : ''}`} onMouseOver={() => onHover(poll)} onMouseOut={() => setHover('')}>
                                <h6 className="accordion-header">
                                    <Link to="/polls/1" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <img src={location.pathname.startsWith('/poll') || hover === poll ? pollswhite : pollsblack} className='me-3' alt='name' /> {props.handled && <span>Poll&apos;s</span>}
                                    </Link>
                                </h6>
                            </div>}
                        {/* Add-vehicle */}
                        {(roles.includes('5') || authState.role_id === 1) &&
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/vehicle') ? 'link-active' : ''}`} onMouseOver={() => onHover(vehicle)} onMouseOut={() => setHover('')}>
                                <h6 className="accordion-header">
                                    <Link to="/vehicle/1" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <img src={location.pathname.startsWith('/vehicle') || hover === vehicle ? vehicleWhite : vehicleBlack} className='me-3' alt='name' /> {props.handled && <span>Vehicle's</span>}
                                    </Link>
                                </h6>
                            </div>}
                        {/* Donations */}
                        {(roles.includes('6') || authState.role_id === 1) &&
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/donations') ? 'link-active' : ''}`} onMouseOver={() => onHover(donations)} onMouseOut={() => setHover('')}>
                                <h6 className="accordion-header">
                                    <Link to="/donations/1" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <img src={location.pathname.startsWith('/donations') || hover === donations ? donationWhite : donationBlack} className='me-3' alt='name' /> {props.handled && <span>Donations</span>}
                                    </Link>
                                </h6>
                            </div>}

                        {/* {(authState.role_id === 1) &&
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/privacy-policy') ? 'link-active' : ''}`} onMouseOver={() => onHover(Privacy)} onMouseOut={() => setHover('')} >
                                <h6 className="accordion-header">
                                    <Link to="/privacy-policy" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <img src={location.pathname.startsWith('/privacy-policy') || hover === Privacy ? privacywhite : privacyblack} className='me-3' alt='name' /> {props.handled && <span>Privacy Policy</span>}
                                    </Link>
                                </h6>
                            </div>} */}
                        {/* {(authState.role_id === 1) &&
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/terms-of-conditions') ? 'link-active' : ''}`} onMouseOver={() => onHover(Terms)} onMouseOut={() => setHover('')}>
                                <h6 className="accordion-header">
                                    <Link to="/terms-of-conditions" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <img src={location.pathname.startsWith('/terms-of-conditions') || hover === Terms ? termswhite : termsBlack} className='me-3' alt='name' /> {props.handled && <span>Terms of Services</span>}
                                    </Link>
                                </h6>
                            </div>} */}

                        {/* about-us  */}
                        {/* {(authState.role_id === 1) ?
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/about-us') ? 'link-active' : ''}`}>
                                <h6 className="accordion-header">
                                    <Link to="/about-us" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <i className={`fa fa-info-circle fs-5 ${props.handled ? "me-3" : "mx-auto"}`}></i> {props.handled && <span>About Us</span>}
                                    </Link>
                                </h6>
                            </div> : ""} */}
                        {/* Notification  */}
                        {(authState.role_id === 1) ?
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/notification') ? 'link-active' : ''}`}>
                                <h6 className="accordion-header">
                                    <Link to="/notifications" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <i className={`fa fa-bell fs-5 ${props.handled ? "me-3" : "mx-auto"}`}></i> {props.handled && <span>Notification</span>}
                                    </Link>
                                </h6>
                            </div> : ""}

                        {(authState.role_id === 1) ?
                            <div className={`accordion-item rounded-0 ${location.pathname.startsWith('/database') ? 'link-active' : ''}`}>
                                <h6 className="accordion-header">
                                    <Link to="/database" className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link">
                                        <i className={`fa fa-database fs-5 ${props.handled ? "me-3" : "mx-auto"}`}></i> {props.handled && <span>DB Backup</span>}
                                    </Link>
                                </h6>
                            </div> : ""}
                    </div>
                </div>
            </div>
            {/* } */}
        </>
    )
}

export default TheSideBar;