import { Link, useMatch } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi';
import { rolesValue } from './add-staff';
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';
import Spinner from '../common/BootstrapCompo';
import Swal from 'sweetalert2';
import Placeholder from '../common/Placeholder';
import { goBack } from '../../utils/CommonFunctions';
const ViewStaff = () => {
    const match = useMatch("staff/:_id/view")
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)


    const [state, setState] = useState<any>({
        data: {
            _id: "",
            name: "",
            profile_pic: "",
            email: "",
            country_code: "",
            phone_number: "",
            roles: [],
            super_admin: false,
            is_block: '',
            is_deleted: false,
            created_at: "",
            blockLoading: false,
            deleteLoading: false,

        }
    })


    const initialise = async () => {
        if (!loaded) {
            setLoading(true)
        }
        try {
            const apiRes = await henceforthApi.Staff.get(match?.params?._id as string)
            setState((state: any) => { return { ...state, ...apiRes.data } })
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false)
            setState((state: any) => {
                return {
                    ...state,
                    blockLoading: false,
                    deleteLoading: false

                }
            })
        }
    }

    const handleStaffEvents = (value: string, message: string) => {
        Swal.fire({
            title: `Are you sure you want to ${message} <br /> <span>user ${state?.name}</span> ? `,
            showCancelButton: true,
            confirmButtonColor: "#FE0002",
            cancelButtonColor: "transparent",
            confirmButtonText: "Yes, Confirm",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    if (value === 'Delete') {
                        setState({
                            ...state,
                            deleteLoading: true
                        })
                    } else {
                        setState({
                            ...state,
                            blockLoading: true
                        })
                    }
                    if (value === 'Delete') return await henceforthApi.Staff.delete(match?.params._id as string), goBack()
                    if (value === 'Block') return await henceforthApi.Staff.staffBlock(match?.params?._id as string), initialise()
                } catch (error) {
                    handleError(error)
                }
            }
        })
    }

    useEffect(() => {
        setLoaded(true)
        initialise()
    }, [])

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            <h3 className='  p-4 bg-white border-bottom'>View Staff</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='change-password'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                {/* Title  */}
                                <div className="common-card border bg-white rounded">
                                    <div className="common-card-title py-3 px-4 border-bottom">
                                        <h5>Staff Profile Detail</h5>
                                    </div>
                                    {/* Profile  */}
                                    {loading ? <Placeholder length={6} /> : <div className="common-card-content">
                                        {/* Profile image  */}
                                        <div className="profile-image text-center py-4 border-bottom">
                                            <div className="edit-pofile-image">
                                                <img src={state.profile_pic ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${state?.profile_pic} ` : defaultUserImg} alt="img" className='img-fluid' />
                                            </div>
                                        </div>
                                        {/* Profile Detail  */}
                                        <div className="profile-details py-4 px-4">
                                            <div className="d-flex gap-2 gap-lg-5 flex-wrap justify-content-between">
                                                <div className='d-flex gap-3'>
                                                    <label htmlFor="staffName" className='text-gray'>Name :</label>
                                                    <p>{state?.name}</p>
                                                </div>
                                                <div className='staff-name d-flex gap-3'>
                                                    <label className='text-gray'>Email:</label>
                                                    <p>{state?.email}</p>
                                                </div>
                                            </div>
                                            {/* role */}
                                            <div className="role d-flex py-3">
                                                <label htmlFor="role" className='me-2 text-gray text-nowrap'>Roles :</label>
                                                <ul className='d-flex flex-wrap gap-1'>
                                                    {Array.isArray(state.access_role_name
                                                    ) && state.access_role_name.length ? state.access_role_name.sort().map((roles: any, index: number) =>
                                                        <span className='span-red me-1' key={index}>
                                                            {rolesValue.find((resp) => resp.value === (Number(roles)))?.role}
                                                        </span>
                                                    ) : 'Not Available'}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* button  */}
                                        <div className="profile-button px-4 pb-4">
                                            <ul className='list-unstyled d-flex gap-2 flex-wrap flex-lg-nowrap'>
                                                <li> <Link to={`/staff/${match?.params?._id}/edit`} className='btn btn-primary px-3 py-2'>Edit</Link >
                                                </li >
                                                <li><button type='button' className='btn btn-outline-danger' onClick={() => handleStaffEvents('Delete', 'Delete')} disabled={state.deleteLoading} >{state.deleteLoading ? <Spinner /> : "Delete"}</button></li>
                                                <li>
                                                    < button type='button' onClick={() => handleStaffEvents('Block', state.is_block === 1 ? 'Unblock' : 'Block')} className='btn btn-theme px-3 py-2' disabled={state.blockLoading}>{state.blockLoading ? <Spinner /> : (state.is_block === 1 ? 'Unblock' : 'Block')}</button>
                                                </li>
                                                <li>
                                                    <Link to={`/staff/${match?.params._id}/reset-password`}>
                                                        < button type='button' className='btn btn-theme text-black px-2 py-2 text-nowrap'>Reset Password</button>
                                                    </Link>
                                                </li>
                                            </ul >
                                        </div >
                                    </div >}
                                </div >
                            </div >
                        </div >
                    </div >
                </section >
            </div >
        </>
    )
}
export default ViewStaff;