import React, { createContext, ReactNode, SetStateAction, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import auth from './reducers/auth';
import logoutSuccess from "./actions/auth/logoutSuccess";
import { message } from 'antd';
import { authState } from './interfaces';
interface CommonContextType {
    loading: boolean
    setLoading: React.Dispatch<SetStateAction<boolean>>
    toastMessage: (message: string) => void
    authState: authState
    authDispatch: any
    logOutNow: () => void
    downloadCSV: any
    Toast: {
        success: (content: string) => void,
        error: (content: string) => void,
        warning: (content: string) => void,
    }
    downloadFile: any

}

export const GlobalContext = createContext({} as CommonContextType);
export const handleError = (error: any) => toast.error((typeof error?.response?.body?.message === "string") ? error?.response?.body?.message : JSON.stringify(error?.response?.body?.message))


export const downloadFile = (file_path: string) => {
    var a: any = document.createElement('a') as HTMLElement;
    a.href = file_path;
    a.target = "_blank";
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
type GlobleContextProviderProps = {
    children: ReactNode;
}

function GlobalProvider(props: GlobleContextProviderProps) {
    const location = useLocation()

    const [messageApi, contextHolder] = message.useMessage();

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [authState, authDispatch] = useReducer(auth, {}, () => {
        const localAuthState = localStorage.getItem("authState");
        return localAuthState ? JSON.parse(localAuthState) : {}
    })

    const scrollToTop = () => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }
    const logOutNow = () => {
        logoutSuccess({})(authDispatch);
        navigate("/", { replace: true });
    };
    const toastMessage = (msg: string) => {
        toast.success(msg)
    }

    useEffect(() => {
        localStorage.setItem("authState", JSON.stringify(authState))
    }, [authState]);

    useEffect(scrollToTop, [location.pathname])
    useEffect(() => {
        if (!localStorage.getItem('authState')) {
            logOutNow()
        }
        // eslint-disable-next-line
    }, [localStorage.getItem('authState')]);

    const Toast = {
        success: (content: string) => {
            messageApi.open({
                type: 'success',
                content,
            });
        },
        error: (content: string) => {
            messageApi.open({
                type: 'error',
                content,
            });
        },
        warning: (content: string) => {
            messageApi.open({
                type: 'warning',
                content,
            });
        },
    }

    const createCsv = async (data: any) => {
        let keys = Object?.keys(data[0]);
        let result = '';
        result += keys.join(',');
        result += '\n';
        data?.forEach((element: any) => {
            keys.forEach((key) => {
                result += element[key] + ',';
            })
            result += '\n';//Creates New Row
        });
        return result;
    }

    const downloadCSV = async (array: any) => {
        if (array.length > 0) {
            let csv = 'data:text/csv;charset=utf-8,' + await createCsv(array);
            let excel = encodeURI(csv);
            let link = document.createElement('a');
            link.setAttribute('href', excel);
            link.setAttribute('download', 'Stay.csv'); //Filename that CSV is saved as
            link.click();
        }
        return
    }

    return (
        <GlobalContext.Provider
            value={{
                loading,
                setLoading,
                authState,
                authDispatch,
                logOutNow,
                toastMessage,
                Toast,
                downloadFile,
                downloadCSV

            }}>
            {contextHolder}
            {props.children}
            <ToastContainer autoClose={2000} />
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
