import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import InputAddresses from "../services/InputAddresses";
import Spinner from "../common/BootstrapCompo";
import { Popconfirm, Select } from 'antd';
import uiSettings, { htmlTable } from "../../utils/uiSettings";
import HenceforthGoogleMap from "./HenceforthGoogleMap";
import henceforthApi from "../../utils/henceforthApi";
import { GlobalContext, handleError } from "../../context/Provider";
import { useMatch } from "react-router-dom";
import { goBack } from "../../utils/CommonFunctions";
import { Button, Popover } from 'antd';
import { toast } from "react-toastify";
import resetIcon from '../../assets/icons/Location-Points.svg'
var userMarker: Array<google.maps.Marker> = [];
var userMarkerFst = null as any;
var gLoading = false
var cityCircle = null as any;
const clearwayCoordinatesExistingArray = [
  {
    "lat": 30.750739452294255,
    "lng": 76.66063555665879
  },
  {
    "lat": 30.750739452294255,
    "lng": 76.66176744886307
  },
  {
    "lat": 30.750255382193373,
    "lng": 76.66237899251847
  },
  {
    "lat": 30.74931259106036,
    "lng": 76.66341968961625
  },
  {
    "lat": 30.748353654670108,
    "lng": 76.66440674253373
  },
  {
    "lat": 30.748316772310588,
    "lng": 76.660673107585
  },
  {
    "lat": 30.74848274281725,
    "lng": 76.65880629011063
  }
]
const ClearwayzoneEdit = () => {
  const defaultProps = {
    center: {
      lat: -37.82168417163056,
      lng: 145.05854992101723
    },
    zoom: 12
  };
  const match = useMatch('/clearwayzone/:id/edit')
  const { authState, Toast } = useContext(GlobalContext)
  henceforthApi.setToken(authState.access_token)
  const googleMapRef = useRef(null as any)
  const [onAddressChanged, setOnAddressChanged] = useState(defaultProps)
  const [showGoogleAddressInput, setShowGoogleAddressInput] = useState(false)
  const [prevLocation, setPrevLocation] = useState([])
  const [name, setName] = useState("")
  const [location_address, setLocationAddress] = useState("")
  const [daysOf, setDaysOf] = useState('1')
  // const [timezone, setTimezone] = useState("America/Los_Angeles")
  // const [startTime, setStartTime] = useState("")
  // const [endTime, setEndTime] = useState("")
  const [message, setMessage] = useState("")
  const [type, setType] = useState("Tow-away zone")
  const [loading, setLoading] = useState(false)
  const locationSearchRef = useRef() as any
  const [showPreview, setShowPreview] = useState(false)
  const [open, setOpen] = useState(false);
  const [proposedChangeInfo, setProposedChangeInfo] = useState("Clearway")
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const createMerker = (position: google.maps.LatLng | google.maps.LatLngLiteral, map: google.maps.Map, icon?: any) => {
    return new google.maps.Marker({
      position,
      map,
      draggable: false,
      animation: google.maps.Animation.DROP,
      label: `${userMarker.length + 1}`,
      icon: icon
    });
  }

  function setMapOnAll(map: google.maps.Map | null) {
    for (let i = 0; i < userMarker.length; i++) {
      userMarker[i].setMap(map);
    }
  }
  // const removeStateData = async (clearwayPolyline: google.maps.Polyline) => {
  //   setTimeout(() => {
  //     // clearwayPolyline.setMap(null);
  //     userMarker = []
  //     gLoading = false
  //     setName("")
  //     setDaysOf('')
  //     setMessage("")
  //     setType("")
  //     setLoading(gLoading)

  //   }, 2000)
  //   setMapOnAll(null)
  // }

  const selectedMarkersShow = async ({ id, name, location, days_name, timezone, start_time, end_time, description, locations }: any) => {
    // debugger
    const stepDisplay = new google.maps.InfoWindow;
    const path = locations.map((res: any) => (
      { lat: Number(res.lat), lng: Number(res.lng) })
    );
    const clearwayPolyline = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    let days = uiSettings.WeekName?.find(res => res.value === days_name[0])?.label
    const info = {
      path,
      days,
      proposed: description,
    } as any

    clearwayPolyline.setMap(googleMapRef.current.map_);
    google.maps.event.addListener(clearwayPolyline, 'click', (evt: any) => {
      stepDisplay.setContent(htmlTable(info));
      stepDisplay.setPosition(evt.latLng);
      stepDisplay.open(googleMapRef.current.map_);
    })
    return clearwayPolyline
  }
  const editClearWay = async () => {
    try {
      // const clearwayCoordinates = userMarker.map(res => (
      //   { lat: res.getPosition()!.lat(), lng: res.getPosition()!.lng() })
      // );
      // console.log('clearwayCoordinates', clearwayCoordinates);
      let currentLocations = userMarker.map(res => (
        { lat: res.getPosition()!.lat(), lng: res.getPosition()!.lng() })
      )
      if (!currentLocations.length) return toast.warn('Please add locations')
      let items = {
        name: name,
        location: locationSearchRef.current.value,
        description: message,
        locations: currentLocations,
        days: [daysOf]
      }
      // start_time: '00:01',
      // end_time: '12:01',
      // timezone: 'America/Los_Angeles',
      // console.log(items);

      // if (clearwayCoordinates.length) {
      gLoading = true
      setLoading(gLoading)
      const apiRes = await henceforthApi.ClearWayaZone.edit(match?.params.id, items)
      // const clearwayPolyline = await selectedMarkersShow(items)
      Toast.success(apiRes.message)
      goBack()
      // await removeStateData(clearwayPolyline)
      // initialise()
      // } else {
      //   Toast.warning("Please insert way of markers")
      // }

    } catch (error) {
      console.log('addClearWay error', error);
      handleError(error)

    } finally {
      gLoading = false
      setLoading(gLoading)
    }
  }

  // const addMarkerByMapEvent = (position: google.maps.LatLng | google.maps.LatLngLiteral, map: google.maps.Map) => {
  //   let marker = createMerker(position, map)

  //   let oldMapMarker = userMarker
  //   console.log('addMarkerByMapEvent userMarker', userMarker);
  //   oldMapMarker.push(marker)
  //   userMarker = [...oldMapMarker]
  //   marker.addListener('dragend', (e: any) => {
  //     console.log(e.latLng.lat());
  //   });
  // }
  let infowindow: any;

  const addMarkerByMapEvent = (position: google.maps.LatLng | google.maps.LatLngLiteral, map: google.maps.Map) => {
    let marker = createMerker(position, map)
    let oldMapMarker = userMarker
    oldMapMarker.push(marker)
    userMarker = [...oldMapMarker]
    // marker.addListener('dragend', (e: any) => {
    //   console.log(e.latLng.lat());
    // });
    const lastIndex = userMarker.length - 1

    // create a popover element for remove last index icon 
    const divHtml = document.createElement("div")
    divHtml.className = "d-flex flex-column shadow text-danger"
    const paragraphHtml = document.createElement("p")
    paragraphHtml.innerHTML = "Do you want to remove?"
    const buttonHtml = document.createElement("button")
    buttonHtml.className = "btn btn-primary mt-2"
    buttonHtml.innerHTML = "Yes"


    // Add listener to that element to remove last icon 
    buttonHtml.addEventListener("click", (e) => {
      console.log(e);
      console.log('lastIndex', lastIndex);
      if (lastIndex === lastIndex) {
        console.log('clicked');
        const removeValue = userMarker.pop()
        const removeMarker = (removeValue as any).setMap(null)
      }
      if (userMarker.length === 0) {
        setShowPreview(false)
      }
    })

    // Appending that element to DOM
    divHtml.appendChild(paragraphHtml)
    divHtml.appendChild(buttonHtml)


    // creating a popover window 
    infowindow = new google.maps.InfoWindow({
      content: divHtml,
    });

    // create a listener for click event on map icons
    marker.addListener('click', (e: any) => {
      const lastIndex1 = userMarker.length - 1
      if (lastIndex === lastIndex1) {
        infowindow.open(map, marker);
        // const removeValue = userMarker.pop()
        // const removeMarker = (removeValue as any).setMap(null)
      }
      if (userMarker.length === 0) {
        setShowPreview(false)
      }
    });
  }

  const onGoogleApiLoaded = ({ map, maps, ref }: any) => {
    setShowGoogleAddressInput(true)

    map.addListener("click", (event: google.maps.MapMouseEvent) => {
      console.log('Add Clearway loading', gLoading);
      // if (!gLoading) {
      addMarkerByMapEvent(event.latLng!, map);
      // }
    });
    initPlaceAPI()
  }



  const initPlaceAPI = () => {
    if (locationSearchRef) {
      let autocomplete = new (window as any).google.maps.places.Autocomplete(
        locationSearchRef.current
      );
      new (window as any).google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          let place = autocomplete.getPlace();
          let formatAddress = place.formatted_address
          const address = place.address_components
          console.log(address);
          let items: any = {}
          if (Array.isArray(address) && address.length > 0) {
            let zipIndex = address.findIndex(res => res.types.includes("postal_code"))
            let administrativeAreaIndex = address.findIndex(res => res.types.includes("administrative_area_level_1", "political"))
            let localityIndex = address.findIndex(res => res.types.includes("locality", "political"))
            let countryIndex = address.findIndex(res => res.types.includes("country", "political"))
            let premiseIndex = address.findIndex(res => res.types.includes("premise", "street_number"))
            let sublocality1 = address.findIndex(res => res.types.includes('sublocality_level_1', 'sublocality', 'political'))
            let sublocality2 = address.findIndex(res => res.types.includes('sublocality_level_2', 'sublocality', 'political'))
            let route = address.findIndex(res => res.types.includes('route'))
            let subpremise = address.findIndex(res => res.types.includes('subpremise'))
            let street_number = address.findIndex(res => res.types.includes('street_number'))
            if (zipIndex > -1) {
              items.pin_code = address[zipIndex]?.long_name
            }
            if (administrativeAreaIndex > -1) {
              items.state = address[administrativeAreaIndex]?.long_name
            }
            if (localityIndex > -1) {
              items.city = address[localityIndex]?.long_name
            }
            if (countryIndex > -1) {
              items.country = address[countryIndex]?.long_name
            }
            if (premiseIndex > -1) {
              items.apartment_number = address[premiseIndex]?.long_name
            }
            items.full_address = formatAddress
            items.sublocality1 = address[sublocality1]?.long_name
            items.sublocality2 = address[sublocality2]?.long_name
            items.subpremise = address[subpremise]?.long_name
            items.route = address[route]?.long_name
            items.street_number = address[street_number]?.long_name
            let zoom = 12
            if (items?.country && items?.state && items?.city && items?.sublocality1 && (items?.sublocality2 || items?.route) && (items?.subpremise || items?.street_number)) zoom = 18
            if (items?.country && items?.state && items?.city && items?.sublocality1 && (items?.sublocality2 || items?.route) && items?.subpremise === undefined && items?.street_number === undefined) zoom = 18
            if (items?.country && items?.state && items?.city && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 15
            if (items?.country && items?.state && items?.city === undefined && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 8
            if (items?.country && items?.state === undefined && items?.city === undefined && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 5
            setLocationAddress(items?.full_address ? items?.full_address : `${items?.city},${items?.state},${items?.country}`)
            setOnAddressChanged((onAddressChanged: any) => {
              return {
                ...onAddressChanged,
                center: {
                  ...onAddressChanged.center,
                  lat: place?.geometry?.location?.lat(),
                  lng: place?.geometry?.location?.lng(),
                },
                zoom: zoom
              }
            })
          }

        }
      );
    }
  }

  const initialise = async () => {
    try {
      // const apiRes = await henceforthApi.ClearWayaZone.pagination('', '', '')
      const apiResDetail = (await henceforthApi.ClearWayaZone.detail(String(match?.params.id))).data
      // const apiData = apiRes.data
      // const { current_page, data, from, to, total } = apiData
      if (Array.isArray(apiResDetail?.locations) && apiResDetail?.locations.length) {
        apiResDetail?.locations.forEach((res: any) => {
          // selectedMarkersShow(apiResDetail)
          let latlng = new (window as any).google.maps.LatLng(
            res?.lat,
            res?.lng
          )
          addMarkerByMapEvent(latlng, googleMapRef.current.map_)
        })
      }
      setName(apiResDetail.name)
      locationSearchRef.current.value = apiResDetail?.location
      setDaysOf(apiResDetail?.days_name[0])
      setMessage(apiResDetail?.description)
      setPrevLocation(apiResDetail?.locations)
      setOnAddressChanged((onAddressChanged: any) => {
        return {
          ...onAddressChanged,
          center: {
            lat: apiResDetail?.locations[0]?.lat,
            lng: apiResDetail?.locations[0]?.lng
          },
          zoom: 16
        }
      })

    } catch (error) {

    }
  }

  React.useEffect(() => {
    if (showGoogleAddressInput) {
      initialise()
    }
    return () => {
      if (showGoogleAddressInput) {
        setMapOnAll(null)
        userMarker = []
      }
    }
  }, [showGoogleAddressInput])

  return (
    <div className="container-fluid px-0">

      {/* header */}
      <div className="add-clearway-header bg-white py-4 px-4 border-bottom">
        <h3>Edit Clearwayzone</h3>
      </div>

      <div className="add-clearway-content px-3">
        <div className="row gy-4 py-4 mx-0">
          {/* clearway-form */}
          <div className="col-md-6">
            {/* {showGoogleAddressInput && */}
            {/* // <InputAddresses setOnAddressChanged={setOnAddressChanged} />} */}

            <div className="add-clearway-form bg-white py-3 px-2 rounded h-100">
              <form onSubmit={(e) => { e.preventDefault(); }} className='px-3'>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <input type="text" className="form-control border" placeholder="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Location</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    {/* <input type="text" className="form-control border" placeholder="Location" name="location_address" value={location_address} onChange={(e) => setLocationAddress(e.target.value)} required /> */}
                    <input type="text" ref={locationSearchRef} className="form-control border" placeholder="Location" disabled name="location_address" />
                  </div>
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Select Day</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <Select
                      className="form-control border-white border px-3 py-2"
                      // mode="multiple"
                      // allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      value={daysOf}
                      onChange={setDaysOf}
                      options={uiSettings.WeekName}
                    />
                  </div>
                </div> */}
                {/* <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Time Zone</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <Select
                      className="form-control"
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      onChange={setTimezone}
                      value={timezone}
                      defaultValue={"America/Los_Angeles"}
                      options={uiSettings.TimeZone}
                    />
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Start Time</label>
                      <div className="input-group mb-3" id="exampleFormControlInput1">
                        <TimePicker
                          className="form-control border"
                          format={"HH:mm"}
                          onChange={(time: any, timeString: string) => { setStartTime(timeString) }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">End Time</label>
                      <div className="input-group mb-3" id="exampleFormControlInput1">
                        <TimePicker
                          className="form-control border"
                          format={"HH:mm"}
                          onChange={(time: any, timeString: string) => { setEndTime(timeString) }} />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="mb-4 pb-2">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                  <textarea className="form-control border" id="floatingTextarea2" placeholder="Please write here message" style={{ height: '100px' }} name="message" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                </div> */}
                {/* button */}
                <div className="">
                  <ul className="list-unstyled d-flex flex-wrap gap-3 align-items-center">
                    <li>
                      <Popover
                        content={<a className="text-decoration-none text-danger" onClick={() => { hide(); goBack(); }}>Yes</a>}
                        title="Do you want to cancel Adding Clearway?"
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                      >
                        <Button type="primary" value='large' className='btn btn-theme px-4' htmlType="button" style={{ height: '40px' }}
                        >
                          Cancel
                        </Button>
                      </Popover>

                    </li>
                    <li>
                      {/* <Form.Item> */}
                      <Button type="primary" value='large' onClick={editClearWay} loading={loading} className='btn btn-primary' htmlType="button" style={{ height: '40px' }}
                      >
                        <span className='align-middle'> Save Changes</span>
                      </Button>
                      {/* </Form.Item> */}
                    </li>
                    {showPreview && <li className="">
                      <Popconfirm
                        title="Delete the location"
                        description="Are you sure to reset all location?"
                        okText="Yes"
                        okType="danger"
                        cancelText="No"
                        onConfirm={() => {
                          setMapOnAll(null)
                          userMarker = []
                          // previewClearwayPolyline?.setMap(null);
                          // setShowPreview(false)
                        }}

                      >
                        <a href="#" className="nav-link fw-500 text-primary btn-outline-danger p-2 rounded-3">
                          <img src={resetIcon} alt="icon" />
                          <span className="ms-2">Remove all pins </span>
                        </a>
                      </Popconfirm>
                    </li>}
                    <li className="">
                      <Popconfirm
                        title="Delete the location"
                        description="Are you sure to reset last location?"
                        okText="Yes"
                        okType="danger"
                        cancelText="No"
                        onConfirm={() => {
                          // setMapOnAll(null)
                          const removeValue = userMarker.pop()
                          const removeMarker = (removeValue as any).setMap(null)
                          // userMarker = []
                          // previewClearwayPolyline?.setMap(null);
                          // setShowPreview(false)
                        }}

                      >
                        <a href="#" className="nav-link fw-500 text-primary btn-outline-danger p-2 rounded-3">
                          <img src={resetIcon} alt="icon" />
                          <span className="ms-2">Remove last pin </span>
                        </a>
                      </Popconfirm>
                    </li>
                    {/* <li>
                      <button className="btn btn-primary border px-4 py-2" type="button" onClick={editClearWay} disabled={loading}>{loading ? <Spinner /> : 'Save Changes'} </button>
                    </li> */}
                    {/* <button className="btn border px-4 py-2" onClick={goBack}>Cancel</button> */}
                  </ul>
                </div>

              </form>
            </div>

          </div>

          {/* clearway-map */}
          <div className="col-md-6">

            <div style={{ height: "100vh", width: "100%" }}>
              <HenceforthGoogleMap
                ref={googleMapRef}
                defaultCenter={defaultProps.center}
                center={onAddressChanged.center}
                zoom={onAddressChanged.zoom}
                defaultZoom={defaultProps.zoom}
                onGoogleApiLoaded={useMemo(() => onGoogleApiLoaded, [])} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ClearwayzoneEdit;
