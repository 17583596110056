import React, { Fragment } from 'react';
import './App.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { GlobalContext } from './context/Provider';
import TheLayout from './layouts/TheLayout';
import ChangePassword from './components/auth/change-password';
import Profile from './components/auth/profile';
import EditProfile from './components/auth/edit-profile';
import AddStaff from './components/staff/add-staff';
import EditStaff from './components/staff/edit-staff';
import StaffListing from './components/staff/staff-listing';
import ViewStaff from './components/staff/view-staff';
import UserDetails from './components/user/UserDetails';
import Home from './components/dashboard/home';
import ContactUs from './components/contact-us/contact-us';
import PrivacyPolicy from './components/content/PrivacyPolicy';
import FaqList from './components/faq/faq';
import FaqEdit from './components/faq/edit-faq';
import FaqAdd from './components/faq/add-faq';
import SignIn from './components/auth/signin';
import PolylineArray from './components/map/ClearwayZoneAdd';
import ClearwayzoneList from './components/map/ClearwayzoneList';
import ClearwayzoneView from './components/map/ClearwayzoneView';
import PollListing from './components/polls/PollListing';
import ViewPolls from './components/polls/ViewPolls';
import EditPolls from './components/polls/EditPolls';
import CreatePoll from './components/polls/createPoll';
import UserListing from './components/user/UserListing';
import ClearwayzoneEdit from './components/map/ClearwayzoneEdit';
import henceofrthEnums from './utils/henceofrthEnums';
import NavigationPage from './components/common/NavigationPage';
import ResetPassword from './components/staff/reset-password';
import TermsCondition from './components/content/TermsCondition';
import Database from './components/Database/Database';
import Notifications from './components/notifications/notifications';
import NotificationOrderList from './components/notifications/notification-order-list';
import AboutUs from './components/content/AboutUs';
import Donations from './components/donations/Donations';
import VehicleListing from './components/addVehicle/vehicle-listing';
import ViewVehicle from './components/addVehicle/view-vehicle';
import AddEditVehicle from './components/addVehicle/add-edit-vehicle';


function App() {

  const { authState } = React.useContext(GlobalContext);
  const navigate = useNavigate()

  return (
    <>
      <Routes>
        {authState.access_token ?
          <Fragment>
            <Route path="*" element={<TheLayout />}>

              <Route index element={authState.access_role_name.includes(henceofrthEnums.Roles.DASHBOARD) ? <Home /> : authState.access_role_name.includes(henceofrthEnums.Roles.USERS) ? <NavigationPage url="/users/1" /> : authState.access_role_name.includes(henceofrthEnums.Roles.CLEARWAY_ZONE) ? <NavigationPage url="/clearwayzone/1" /> : authState.access_role_name.includes(henceofrthEnums.Roles.POLL) ? <NavigationPage url="/polls/1" /> : authState.access_role_name.includes(henceofrthEnums.Roles.DONATIONS) ? <NavigationPage url="/donations/1" /> : authState.access_role_name.includes(henceofrthEnums.Roles.VEHICLES) ? <NavigationPage url="/vehicle/1" /> : <Profile />} />

              {/***************** Profile ****************/}
              <Route path='profile' element={<Profile />} />
              <Route path='change-password' element={<ChangePassword />} />
              <Route path='profile/edit' element={<EditProfile />} />

              {/***************** Staff ****************/}
              {authState.role_id === 1 &&
                <Fragment>
                  <Route path="staff/add" element={<AddStaff />} />
                  <Route path="staffs/:page" element={<StaffListing />} />
                  <Route path="staff/:_id/edit" element={<EditStaff />} />
                  <Route path="staff/:_id/view" element={<ViewStaff />} />
                  <Route path="staff/:_id/reset-password" element={<ResetPassword />} />
                </Fragment>}

              {/***************** User ****************/}
              {authState.access_role_name.includes(henceofrthEnums.Roles.USERS) &&
                <Fragment>
                  <Route path="users/:page" element={<UserListing />} />
                  <Route path="user/:id" element={<UserDetails />} />
                </Fragment>}

              {/* clearwayzone */}
              {authState.access_role_name.includes(henceofrthEnums.Roles.CLEARWAY_ZONE) &&
                <Fragment>
                  <Route path="clearwayzone/:page" element={<ClearwayzoneList />} />
                  <Route path="clearwayzone/:id/view" element={<ClearwayzoneView />} />
                  <Route path="clearwayzone/add" element={<PolylineArray />} />
                  <Route path="clearwayzone/:_id/edit" element={<ClearwayzoneEdit />} />
                </Fragment>}

              {/* polls */}
              {authState.access_role_name.includes(henceofrthEnums.Roles.POLL) &&
                <Fragment>
                  <Route path="polls/:page" element={<PollListing />} />
                  <Route path="polls/add" element={<CreatePoll />} />
                  <Route path="poll/:_id/view" element={<ViewPolls />} />
                  <Route path="poll/:_id/edit" element={<EditPolls />} />
                </Fragment>}

              {/* Add-vehicle */}
              {authState.access_role_name.includes(henceofrthEnums.Roles.VEHICLES) &&
                <Fragment>
                  <Route path='vehicle' >
                    <Route path=":page" element={<VehicleListing />} />
                    <Route path=":id/view" element={<ViewVehicle />} />
                    <Route path=':id/edit' element={<AddEditVehicle />} />
                    <Route path='add' element={<AddEditVehicle />} />
                  </Route>
                </Fragment>}

              {/***************** Donations ****************/}
              {authState.access_role_name.includes(henceofrthEnums.Roles.DONATIONS) &&
                <Route path="donations/:page" element={<Donations />} />}

              {/* //Datbase  */}
              <Route path="database" element={<Database />} />

              {/* //Notification */}
              <Route path="notifications" element={<Notifications />} />
              <Route path="order/:id" element={<NotificationOrderList />} />


              {/***************** FAQ ****************/}
              <Route path="faq" element={<FaqList />} />
              <Route path={`faq/:id/edit`} element={<FaqEdit />} />
              <Route path="faq/add" element={<FaqAdd />} />

              {/***************** Contact Us ****************/}
              {/* <Route path="contact-us/:page" element={<ContactUs />} /> */}

              {/***************** Content ****************/}
              {/* <Route path="content" element={<Content />} /> */}
              {/* <Route path="privacy-policy" element={<PrivacyPolicy />} /> */}
              {/* <Route path="terms-of-conditions" element={<TermsCondition />} /> */}
              {/* <Route path="about-us" element={<AboutUs />} /> */}
              {/* <Route path="view-content/:id" element={<ViewContent />} /> */}

            </Route>

          </Fragment>
          :

          <Route path="*" element={<SignIn />} />
        }
      </Routes>
    </>
  )
}

export default App;
