import { useContext, useEffect, useState } from 'react';
import view from '../../assets/icons/view_blue.svg'
import edit from '../../assets/icons/edit_green.svg'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import PaginationLayout from '../common/PaginationLayout';
import henceforthApi from '../../utils/henceforthApi';
import { handleSearch, onChangePagination } from '../../utils/CommonFunctions';
import moment from 'moment';
import Placeholder from '../common/Placeholder';
import DownloadFileModal from '../common/download-file-modal';
import { GlobalContext, handleError } from '../../context/Provider';
import { Pagination } from 'antd';


function PollListing() {
    const location = useLocation();
    const match = useMatch("/polls/:page");
    const { downloadCSV } = useContext(GlobalContext)
    const newParam = new URLSearchParams(location.search)
    const navigate = useNavigate()

    let limit = 10

    const filterArray = [
        { id: '', name: 'All' },
        { id: '1', name: 'Published' },
        { id: '2', name: 'Draft' },
        { id: '3', name: 'Closed' },
        { id: '4', name: 'Completed' },
    ]

    const [pageData, setpPageData] = useState<any>({
        data: [],
        total: 0
    })

    const [dataLoading, setDataLoading] = useState<boolean>(false)

    const initialise = async () => {
        setDataLoading(true)
        try {
            const apires = await henceforthApi.Poll.pagination(
                newParam.toString(),
                Number(match?.params.page),
            )
            setpPageData({
                data: apires.data.data,
                total: apires.data.total
            })
        } catch {
            console.log("error")
        } finally {
            setDataLoading(false)
        }
    }

    useEffect(() => {
        initialise()
    }, [match?.params.page, newParam.get("search"), newParam.get("filter")])

    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Poll.export(startDate, endDate)
            const data = apiRes.data
            downloadCSV(data)
        } catch (error) {
            handleError(error)
        }
    }



    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="common-card-title border-bottom px-4 py-4 bg-white d-flex justify-content-between">
                                <h3>Poll</h3>
                                <Link to='/polls/add'>
                                    <button className='btn btn-primary'>Create a Poll</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            {/* <div className='page-spacing'> */}
            <section className='product-listing'>
                <div className="container-fluid">
                    {/* search-filter-export */}
                    <div className='common-card mb-4 border-0 card-spacing px-2 px-sm-4 '>
                        <div className="row justify-content-between bg-white border rounded">

                            {/* header */}
                            <div className='col-12 px-0'>
                                <div className='staff-header border-bottom d-flex justify-content-between flex-column flex-sm-row gap-2 align-items-center border-bottom py-3 px-4'>
                                    <h5>List of All Poll&apos;s</h5>
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className="dropdown">
                                            <button className="btn shadow-none dropdown-toggle border py-1 px-3 fw-500 fs-12 rounded " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {newParam.has('filter') ? filterArray.find((res) => res.id === newParam.get('filter'))?.name : 'All'}&nbsp;
                                            </button>
                                            <ul className="dropdown-menu py-2 overflow-hidden">
                                                {filterArray.map((res) => <li onClick={() => handleSearch('filter', res.id, location, navigate, newParam)}><button className="dropdown-item">{res.name}</button></li>)}
                                            </ul>
                                        </div>
                                        <div className='d-flex gap-3 justify-content-md-end '>
                                            <div className='download-export-box text-center'>
                                                {/* <label className='mb-1 form-label fw-semibold'>Export File</label> */}
                                                <div className="export-button border rounded">
                                                    <button className="btn btn-white border-0 d-flex align-items-center py-1" type="button" data-bs-toggle="modal" data-bs-target="#fileDownloadModal"> <i className='fa fa-cloud-download me-2'></i>
                                                        <span>.csv</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* serach and filter  */}
                            <div className="col-12">
                                <div className='position-relative py-3 px-2'>
                                    <input type="search" value={newParam.has('search') ? newParam.get('search') as string : ''} className="form-control rounded-0 ps-4 " name='search' placeholder="Search by Title"
                                        onChange={(e: any) => handleSearch(e.target.name, e.target.value, location, navigate, newParam)}
                                    />
                                </div>
                            </div>

                            {/* table */}
                            <div className='col-12 px-0'>
                                <div className='table-responsive px-0 Recent-clearwayzone'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sr. No.</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Created Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        {dataLoading ? <tbody> <tr><td colSpan={6}><Placeholder length={10} /></td> </tr></tbody> : <tbody>

                                            {Array.isArray(pageData.data) && pageData.data.length ? pageData.data.map((res: any, index: any) =>
                                                <tr key={res.id}>
                                                    <td>
                                                        {Number(match?.params.page) == 0
                                                            ? index + 1
                                                            : (Number(match?.params.page) - 1) * limit + (index + 1)}
                                                    </td>
                                                    <td className='product-image-table'>{res.profile_pic_url && <img src={res?.profile_pic_url ? henceforthApi.FILES.imageSmall(res?.profile_pic_url) : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9qQ2DsiW6blvz_YMjl3MlKDl8GXr09rVN2NNKQ_Mh&s"} alt="img" className='rounded-circle' />} {res.title ? `${res.title} ` : `Not Available`}</td>
                                                    <td>
                                                        {
                                                            (moment(res.created_at).format('YYYY-MM-DD'))
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            res.end_date
                                                                ?
                                                                (moment(res.end_date).format('YYYY-MM-DD'))
                                                                :
                                                                'Unlimited'
                                                        }
                                                    </td>
                                                    <td>
                                                        {/* {Status} */}
                                                        {res.status === 1 && <span className='publish-status'>Published</span>}
                                                        {res.status === 2 && <span className='draft-status fs-12'>Draft</span>}
                                                        {res.status === 3 && <span className='closed-status'>Closed</span>}
                                                        {res.status === 4 && <span className='closed-status'>Completed</span>}
                                                    </td>
                                                    <td><div className="btn-group gap-2">
                                                        <Link to={`/poll/${res.id}/view`} className='btn view-btn border'>
                                                            <img src={view} className='img-fluid me-2' alt='image' />
                                                            <span className='align-middle fs-12'>View</span>
                                                        </Link>
                                                        <Link to={`/poll/${res.id}/edit`} className='btn   view-btn border'>
                                                            <img src={edit} className='img-fluid me-2' alt='image' />
                                                            <span className='align-middle fs-12'>Edit</span>
                                                        </Link>
                                                    </div>
                                                    </td>
                                                </tr>) : <tr><td colSpan={9} className="text-center">No data found</td></tr>}
                                        </tbody>}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* pagination */}
                    {/* <PaginationLayout
                        count={pageData.total}
                        data={pageData.data}
                        limit={limit}
                        page={Number(match?.params.page)}
                    /> */}
                    <div className="text-center mt-2">
                        <Pagination current={Number(match?.params.page)} className='text-primary' onChange={(e: any) => onChangePagination(e, location, navigate, newParam)} total={pageData.total} showSizeChanger={false} />
                    </div>
                </div>

            </section >
            {/* </div > */}
            <DownloadFileModal exportData={exportData} />

        </>
    )
}

export default PollListing