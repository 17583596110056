import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';


const Profile = () => {

    const { authState } = useContext(GlobalContext);

    henceforthApi.setToken(authState.access_token);

    return (<>
        {/* breadcrum  */}
        <section className="breadcrum-box py-0">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 border-bottom bg-white">
                        {/* title  */}
                        <h3 className='py-4 px-3'>Profile</h3>
                    </div>
                </div>
            </div>
        </section>
        {/* page  */}
        <div className='page-spacing'>
            <section className='change-password'>
                <div className="container-fluid">
                    <div className='row justify-content-center py-4'>
                        <div className='col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-5'>
                            <div className='profile-box border bg-white rounded'>
                                {/* profile-header */}
                                <div className='profile-header border-bottom py-3 px-4'>
                                    <h5>Admin Profile Detail</h5>
                                </div>
                                {/* profile-body */}
                                <div className='profile-body py-4'>
                                    <div className='profile-image text-center pb-4 border-bottom'>
                                        <div className="edit-pofile-image">
                                            <img src={authState.profile_pic ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState.profile_pic}` : defaultUserImg} alt="image" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='user-details py-4 my-2 px-4 d-flex justify-content-between flex-wrap'>
                                        <div className='user-name d-flex gap-3'>
                                            <label className='text-gray'>Name : </label>
                                            <p>{authState.name}</p>
                                        </div>
                                        <div className='user-name d-flex gap-3'>
                                            <label className='text-gray'>Email : </label>
                                            <p>{authState.email}</p>
                                        </div>
                                    </div>

                                    <div className='profile-edit-btn px-4'>
                                        <Link to='/profile/edit'>
                                            <button className='btn btn-primary px-4 py-2'>Edit</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
    )
}

export default Profile;