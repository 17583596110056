import view from '../../assets/icons/view_blue.svg'
import edit from '../../assets/icons/edit_green.svg'
import { Link } from 'react-router-dom'
import Placeholder from '../common/Placeholder'
import uiSettings from '../../utils/uiSettings'

interface propsType {
    limit: number
    dataLoading: boolean
    tableData: any
    SerialNo: number
}

const ClearwayzoneListTable = (props: propsType) => {
    const { limit, dataLoading, tableData, SerialNo } = props

    return (
        <>
            <table className="table" >
                <thead>
                    <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Days</th>
                        {/* <th scope="col">Hours</th> */}
                        <th scope="col">Location</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                {dataLoading ? <tbody> <tr><td colSpan={6}><Placeholder length={10} /></td> </tr></tbody> : <tbody>
                    {Array.isArray(tableData) && tableData.length ? tableData.map((res: any, index: number) => <tr key={res.id}>
                        <th scope="row"> {SerialNo == 0
                            ? index + 1
                            : (SerialNo - 1) * limit + (index + 1)}</th>
                        <td>{res.name.length >= 15 ? `${res.name.slice(0, 15)}...` : res.name}</td>
                        <td>
                            <div className="d-flex">
                                {Array.isArray(res.days_name) && res.days_name.map(((day_name: string) => <span className='bg-primary d-inline-flex text-white rounded text-white px-1 me-1'>{(uiSettings.WeekName.find(res => res.value === day_name))?.label}</span>))}
                            </div>
                        </td>
                        {/* <td>{res.start_time} - {res.end_time}</td> */}
                        <td>{res.location.length >= 50 ? `${res.location.slice(0, 50)}...` : res.location}</td>
                        <td>
                            <div className='action-icon d-flex gap-2 align-items-center'>
                                <div className='View text-nowrap'>
                                    <Link to={`/clearwayzone/${res.id}/view`} className='btn view-btn border'>
                                        <img src={view} className='img-fluid me-2' alt='image' />
                                        <span className='align-middle fs-12'>View</span>
                                    </Link>
                                </div>
                                <div className='View text-nowrap'>
                                    <Link to={`/clearwayzone/${res.id}/edit`} className='btn view-btn border'>
                                        <img src={edit} className='img-fluid me-2' alt='image' />
                                        <span className='align-middle fs-12'>Edit</span>
                                    </Link>
                                </div>
                            </div>
                        </td>
                    </tr>
                    ) : <tr><td colSpan={5} className="text-center">No data found</td></tr>}

                </tbody>}
            </table>
        </>
    )
}
export default ClearwayzoneListTable