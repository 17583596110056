import React, { useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import Swal from 'sweetalert2';
import { handleError } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import uiSettings from '../../utils/uiSettings';
import HenceforthGoogleMap from './HenceforthGoogleMap';

function ClearwayzoneView() {
  const googleMapRef = React.useRef(null as any)
  const match = useMatch("clearwayzone/:id/view")

  const [state, setState] = React.useState({
    id: "",
    name: "",
    location: "",
    timezone: "",
    start_time: "",
    end_time: "",
    description: "",
    created_at: "",
    updated_at: "",
    days_name: [],
    locations: [],
    city: ''
  })
  const [onAddressChanged, setOnAddressChanged] = useState(uiSettings.defaultMapValues)

  const selectedMarkersShow = async ({ id, name, location, days, timezone, start_time, end_time, description, locations }: any) => {
    const stepDisplay = new google.maps.InfoWindow;
    const path = locations.map((res: any) => (
      { lat: Number(res.lat), lng: Number(res.lng) })
    );
    const clearwayPolyline = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    console.log(clearwayPolyline);
    const info = {
      path,
      days,
      times: `${start_time} - ${end_time}, ${timezone}`,
      proposed: description,
    } as any

    clearwayPolyline.setMap(googleMapRef.current.map_);
    google.maps.event.addListener(clearwayPolyline, 'click', (evt: any) => {
      stepDisplay.setContent(uiSettings.htmlTable(info));
      stepDisplay.setPosition(evt.latLng);
      stepDisplay.open(googleMapRef.current.map_);
    })
    return clearwayPolyline
  }


  const initialise = async () => {
    try {
      const apiRes = await henceforthApi.ClearWayaZone.get(match?.params.id as string)
      const apiData = apiRes.data
      setState(apiData)
      setOnAddressChanged((onAddressChanged: any) => {
        return {
          ...onAddressChanged,
          center: {
            lat: apiData?.locations[0]?.lat,
            lng: apiData?.locations[0]?.lng
          },
          zoom: 16
        }
      })
      selectedMarkersShow(apiData)
    } catch (error) {

    }
  }
  const onDeleteHandler = () => {
    Swal.fire({
      title: "Are you sure you want to delete <br/> this Clearwayzone?",
      // text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FE0002",
      cancelButtonColor: "transparent",
      confirmButtonText: "Yes, Confirm",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          // setloading(true)
          let apires = await henceforthApi.ClearWayaZone.delete(match?.params.id as string)
          window.history.back()
        } catch (error) {
          handleError(error)
        } finally {
          // setloading(false)
        }
      }
    })
  }
  const onGoogleApiLoaded = async ({ map, maps, ref }: any) => {
    initialise()
  }

  return (
    <>
      <div className="container-fluid px-0">

        {/* header */}
        <div className="add-clearway-header bg-white py-4 px-4 border-bottom">
          <h3>View Clearwayzone</h3>
        </div>

        <div className="add-clearway-content px-3">
          <div className="row gy-4 py-4 mx-0">
            {/* clearway-form */}
            <div className="col-md-6">
              {/* {showGoogleAddressInput && */}
              {/* // <InputAddresses setOnAddressChanged={setOnAddressChanged} />} */}

              <div className="add-clearway-form bg-white py-3 px-4 rounded border vh-100">
                {/* <form className='px-3'> */}
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-gray">Name</label>
                  <p>{state.name}</p>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-gray">Day</label>
                  <ul className="days d-flex gap-1 flex-wrap">
                    {Array.isArray(state.days_name) && state.days_name.map(((day_name: string) => <li key={day_name}>
                      <span className='span-red'>{(uiSettings.WeekName.find(res => res.value === day_name))?.label}</span>
                    </li>))}
                  </ul>
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-gray">Hours</label>
                  <p>{state.start_time} - {state.end_time} {state.timezone}</p>
                </div> */}
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-gray">Location</label>
                  <p>{state.location}</p>
                </div>

                <div className="mb-4 pb-2">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-gray">City</label>
                  <p>{state.city}</p>
                </div>
                {state.description && <div className="mb-4 pb-2">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-gray">Message</label>
                  <p>{state.description}</p>
                </div>}
                {/* button */}
                <ul className="list-unstyled d-flex flex-wrap gap-3">
                  <li>
                    <Link to={`/clearwayzone/${match?.params.id}/edit`}>
                      <button className="btn btn-primary border px-4 py-2" >Edit</button>
                    </Link>
                  </li>
                  <li>
                    <button className="btn btn-outline-danger px-4 py-2" onClick={onDeleteHandler}>Delete</button>
                  </li>

                </ul>
                {/* </form> */}
              </div>

            </div>

            {/* clearway-map */}
            <div className="col-md-6">

              <div style={{ height: "100vh", width: "100%" }} className='h-100'>
                <div style={{ height: "100vh", width: "100%" }}>
                  <HenceforthGoogleMap
                    ref={googleMapRef}
                    center={onAddressChanged.center}
                    zoom={onAddressChanged.zoom}
                    defaultCenter={uiSettings.defaultMapValues.center}
                    defaultZoom={uiSettings.defaultMapValues.zoom}
                    onGoogleApiLoaded={onGoogleApiLoaded} />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default ClearwayzoneView