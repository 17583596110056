import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import uiSettings from '../../utils/uiSettings';
import { useMatch } from 'react-router-dom';
import henceforthApi from '../../utils/henceforthApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { goBack } from '../../utils/CommonFunctions';
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};
export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().startOf('day');
}



function CreatePoll() {
    const [form] = Form.useForm();
    const match = useMatch("poll/:_id/edit")

    let id = match?.params?._id as string

    const [state, setState] = useState<any>({
        title: '',
        multi_select: "",
        status: '',
        end_date: '',
        created_at: ''
    })

    const [multipleOption, setMultipleOption] = useState(false)
    const [visibility, setVisibility] = useState('')
    const [toggleDate, setToggleDate] = useState(false)
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const [publishLoading, setPublishLoading] = useState(false)


    const onFinish = async (values: any) => {
        // if (!toggleDate) return toast.warn('End date required')
        // console.log('Received values of form:', values);
        // debugger
        let items = {
            title: state.title,
            visiblity: 1,
            multi_select: multipleOption ? 1 : '',
            answers: values.options
        } as any

        if (status === "publish") items['status'] = 1
        else items['status'] = (state?.status === 3 ? 1 : state?.status)

        if (toggleDate) {
            items.end_date = state.end_date
        }
        if (status === "publish") {
            setPublishLoading(true)
        }
        else {
            setLoading(true)
        }
        try {
            let resApi =
                await henceforthApi.Poll.edit(
                    id, items)
            toast.success(resApi.message);
            window.history.back()
        } catch (error: any) {
            if (error.response.body.message.title) return toast.error(error.response.body.message.title[0])
            if (error.response.body.message.end_date) return toast.error(error.response.body.message.end_date[0])
        }
        finally {
            setPublishLoading(false)
            setLoading(false)
        }
    };


    const initialise = async () => {
        try {
            const apiRes = await henceforthApi.Poll.get(match?.params?._id as string)
            setState(apiRes.data)
            form.setFieldsValue({ options: apiRes.data.answers.map((res: any) => res.answer) });
            (apiRes.data.end_date ? setToggleDate(true) : setToggleDate(false));
            if (apiRes.data.multi_select) return setMultipleOption(true)

        } catch (error) {
        }
    }

    useEffect(() => {
        initialise()
    }, [])

    const onChange = (date: any) => {
        setState({
            ...state,
            end_date: moment(date.$d).format('YYYY-MM-DD')
        })
    };

    console.log(state,'state');
    

    const defaultDate = moment(new Date()).format('YYYY-MM-DD')

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="common-card-title border-bottom px-4 py-4 bg-white d-flex justify-content-between">
                                <h3>Edit Poll</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='product-listing'>
                    <div className="container-fluid">
                        {/* search-filter-export */}
                        <div className='common-card border-0 card-spacing border p-4 px-4 px-sm-5 mx-sm-3 rounded bg-white'>
                            <div className="row">

                                {/* header */}
                                <Form form={form} layout="vertical" onFinish={onFinish} >

                                    <div className='col-12 px-0'>
                                        <div className="title mb-3">
                                            <label htmlFor="" className='mb-2 fw-400'>Title</label>
                                            {/* <Form.Item> */}

                                            <Input className='w-100 form-control' placeholder="Type your question here" value={state.title} onChange={(e) => setState({
                                                ...state,
                                                title: e.target.value
                                            })} />
                                            {/* </Form.Item> */}
                                        </div>
                                        <div className='answer-option'>
                                            <div className="row">
                                                <div className="col-6">
                                                </div>
                                            </div>
                                            <h6 className='mb-3 fw-400'>Answer Options</h6>
                                            <Form.List
                                                name="options"
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (!names || names.length < 2) {
                                                                return Promise.reject(new Error('Add at least 2 option'));
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Form.Item
                                                                    {...field}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            whitespace: true,
                                                                            message: `Please input ${index === 0 ? 'First' : 'Second'} option`,
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input placeholder="write an option here" style={{ width: '100%' }} className='form-control' />
                                                                </Form.Item>
                                                                {fields.length ? (
                                                                    <CloseOutlined
                                                                        className="dynamic-delete-button ms-1" style={{ position: 'relative', right: '25px' }}
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                ) : null}
                                                            </Form.Item>
                                                        ))}
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            disabled={fields.length === 2}
                                                        >
                                                            Add Option
                                                        </Button>
                                                        <Form.Item>
                                                            <Form.ErrorList errors={errors} />
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </div>
                                    </div>
                                    <hr className='mt-4 mb-4' />
                                    <div className="col-12">
                                        <div className="Settings mb-4">
                                            <h4>Expiry Date</h4>
                                        </div>
                                        <div className="row gy-4">
                                            <div className="col-lg-6">
                                                {/* <div className="form-check form-switch mb-4 d-flex justify-content-between p-0">
                                                    <p>Allow selection of multiple options</p>
                                                    <Switch className='switch' checked={multipleOption} onChange={(e) => setMultipleOption(e)} />
                                                </div> */}
                                                <div className="date">
                                                    {/* <div className="form-check form-switch mb-2 d-flex justify-content-between p-0">
                                                        <h6>Set end date</h6>
                                                        <Switch className='switch' checked={toggleDate} onChange={(e) => setToggleDate(e)} />
                                                    </div> */}
                                                    {/* {toggleDate && */}
                                                        <DatePicker
                                                            format='YYYY-MM-DD'
                                                            className='w-100 form-control'
                                                            value={state.end_date ? dayjs(state.end_date, 'YYYY-MM-DD') : dayjs(defaultDate, 'YYYY-MM-DD')}
                                                            defaultValue={dayjs(defaultDate, 'YYYY-MM-DD')}
                                                            placeholder='YYYY-MM-DD'
                                                            disabledDate={disabledDate}
                                                            onChange={onChange}
                                                            inputReadOnly
                                                        />
                                                    {/* // } */}
                                                    {/* </Space> */}
                                                </div>

                                            </div>
                                            {/* <div className="col-lg-6">
                                                <div className="result">
                                                    <h6 className='mb-4 fw-400'>Results visibility</h6>
                                                    <Select
                                                        className="form-control px-3"
                                                        value={visibility}
                                                        style={{ width: '100%' }}
                                                        placeholder="Please select"
                                                        onChange={setVisibility}
                                                        options={uiSettings.ResultVisiblity}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <ul className='d-flex gap-2 flex-wrap mt-2'>
                                                    <li>
                                                        {state?.status === 2 ? <Button type="primary" value='large' className='btn btn-theme' htmlType="submit" style={{ height: '40px' }}
                                                            onMouseOver={() => setStatus('publish')} disabled={loading} loading={publishLoading}
                                                        >
                                                            Publish
                                                        </Button>
                                                            :
                                                            <li>
                                                                <button className='btn btn-theme px-4 py-2' type='button' onClick={goBack}>Cancel</button>
                                                            </li>
                                                        }
                                                    </li>
                                                    <li>
                                                        {/* <Form.Item> */}
                                                        <Button type="primary" value='large' className='btn btn-primary' htmlType="submit" style={{ height: '40px' }}
                                                            onMouseOver={() => setStatus('changes')} loading={loading} disabled={publishLoading}
                                                        >
                                                            {state?.status === 3 ? 'Republish' : 'Save Changes'}
                                                        </Button>
                                                        {/* </Form.Item> */}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}

export default CreatePoll