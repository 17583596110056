import React, { useEffect, useState } from 'react';
import viewImage from '../../assets/icons/view_blue.svg';
import { Link, useMatch } from 'react-router-dom';
import henceforthApi from '../../utils/henceforthApi';
import { GlobalContext, handleError } from '../../context/Provider';
import Swal from 'sweetalert2';
import moment from 'moment';
import { toast } from 'react-toastify';
import Placeholder from '../common/Placeholder';
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';


function ViewPolls() {
    const match = useMatch("poll/:_id/view")
    const { authState } = React.useContext(GlobalContext);
    henceforthApi.setToken(authState.access_token)

    const [state, setState] = useState<any>([] as Array<object>)
    const [loader, setLoader] = useState(false)
    const [vote_result, setVoteResult] = useState<any>([])
    const [defaultValue, setDefaultValue] = useState<Boolean>(true)

    const [voting_data, setVotingData] = useState([] as Array<object>)


    const initialise = async () => {
        setLoader(true)
        try {
            const apiRes = await henceforthApi.Poll.get(match?.params?._id as string)
            const vote_count = await henceforthApi.Poll.getVotes(match?.params?._id as string)
            { (vote_count?.data?.votes).length === 0 ? setDefaultValue(false) : setDefaultValue(true) }
            setState(apiRes.data)
            let result = (apiRes?.data.answers.map((res: any) => { return { ...res, voters: vote_count.data.votes.filter((resp: any) => resp?.answers?.answer === res?.answer), votersTotalLength: vote_count.data.votes.length } }));
            setVotingData(result)
        } catch (error: any) {
            toast.warn(error.message)
        }
        finally {
            setLoader(false)
        }
    }


    useEffect(() => {
        initialise()
    }, [])

    const onChangeDeletePoll = async () => {
        Swal.fire({
            title: "Are you sure you want to delete <br/> <span>this Poll</span>?",
            showCancelButton: true,
            confirmButtonColor: "#FE0002",
            cancelButtonColor: "transparent",
            confirmButtonText: "Yes, Confirm",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    await henceforthApi.Poll.delete(match?.params._id as string)
                    window.history.back()
                } catch (error) {
                    handleError(error)
                } finally {
                }
            }
        })

    }

    const getID = (id: number) => {
        {
            voting_data.map((res: any) => {
                res.id === id && setVoteResult(res.voters)
            }
            )
        }
    }

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <div className="common-card-title border-bottom px-4 py-4 bg-white">
                                <h3>View Poll</h3>
                            </div>
                            {/* breadcrum  */}
                            <nav aria-label="breadcrumb">
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='view-poll-section'>
                    <div className="container-fluid">
                        {/* search-filter-export */}
                        <div className='common-card mb-4 border-0 card-spacing p-4 '>
                            <div className="row justify-content-between bg-white border rounded">

                                <ul className="nav nav-pills mb-3 border-bottom py-2 px-2" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active me-3 fw-500" id="pills-Detail-tab" data-bs-toggle="pill" data-bs-target="#pills-Detail" type="button" role="tab" aria-controls="pills-Detail" aria-selected="true">Poll Detail</button>
                                    </li>
                                    {(state?.status === 1 || state?.status === 3) && < li className="nav-item" role="presentation">
                                        <button className="nav-link fw-500" id="pills-Result-tab" data-bs-toggle="pill" data-bs-target="#pills-Result" type="button" role="tab" aria-controls="pills-Result" aria-selected="false">Poll Result</button>
                                    </li>}
                                </ul>

                                {loader ? <Placeholder length={6} /> : <div className="tab-content px-4 pb-4 pt-2" id="pills-tabContent">

                                    {/* polls-details */}
                                    <div className="tab-pane fade show active" id="pills-Detail" role="tabpanel" aria-labelledby="pills-Detail-tab" >
                                        <div className="row gy-3">
                                            <div className="col-12">
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        <label htmlFor="Title" className='form-label text-gray'>Title</label>
                                                        <p>{state.title}</p>
                                                    </div>
                                                    <div>

                                                        {state.status === 1 && <span className='publish-status-btn py-2 px-3 fs-12'>Published</span>}
                                                        {state.status === 2 && <span className='draft-status-btn py-2 px-3 fs-12'>Draft</span>}
                                                        {state.status === 3 && <span className='closed-status-btn py-2 px-3 fs-12'>Closed</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="Title" className='form-label text-gray'>Answer Options</label>

                                                <div className="ans-option">
                                                    {
                                                        state?.answers?.map((res: any, index: number) =>
                                                            <div key={index} >{res?.answer}</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label className='form-label text-gray'>Created Date</label>
                                                <p>
                                                    {moment(state.created_at).format('YYYY-MM-DD')}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <label className='form-label text-gray'>End Date</label>
                                                <p>
                                                    {state.end_date
                                                        ? moment(state.end_date).format('YYYY-MM-DD') : 'Unlimited'}
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <ul className='d-flex gap-3 pb-2 pt-3'>
                                                    <li>
                                                        <Link to={`/poll/${match?.params?._id}/edit`}>
                                                            <button className='btn btn-primary px-4'>{state?.status === 3 ? 'Republish' : 'Edit'} </button>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <button className='btn btn-outline-danger' onClick={onChangeDeletePoll}>Delete</button>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* polls-result */}
                                    <div className="tab-pane fade py-2" id="pills-Result" role="tabpanel" aria-labelledby="pills-Result-tab" >
                                        <div className="row">
                                            <div className="col-12">
                                                {defaultValue ?
                                                    <div className="col-sm-9">
                                                        <div className="row gy-4">
                                                            <div className="col-12">
                                                                <h5>{state.title}</h5>
                                                            </div>
                                                            {Array.isArray(voting_data) && voting_data.map((res: any, index: number) =>
                                                                <div className="col-12" key={index}>
                                                                    <p>{res?.answer}</p>
                                                                    <div className='d-flex align-items-center mb-3 mt-2'>
                                                                        <div className="progress w-100">
                                                                            <div className="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: `${res?.voters.length / res.votersTotalLength * 100}%` }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100}>
                                                                            </div>
                                                                        </div>
                                                                        <span className='ms-3'>{Math.round(res?.voters.length / res.votersTotalLength * 100)}%</span>
                                                                    </div>
                                                                    <div className="votes px-2">
                                                                        {res?.voters.map((res: any) =>
                                                                            < img src={res?.user?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.user?.profile_pic}` : defaultUserImg} alt="image" className='img-fluid' />
                                                                        )}
                                                                        <button className='btn text-secondary p-0 border-0 ms-3 fs-14' data-bs-toggle="modal" disabled={res?.voters.length === 0} onClick={() => getID(res?.id)} data-bs-target="#votesModal">{res?.voters.length} Votes</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                            <div className="modal fade polls" id="votesModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title w-100 text-center" id="exampleModalLabel">Votes</h5>
                                                                        </div>
                                                                        <div className="modal-body p-4">
                                                                            <div className="row gy-2">
                                                                                <div className="col-12">
                                                                                    <label htmlFor="title" className='form-label text-gray'>Title</label>
                                                                                    <p className='fs-16'>{state?.title}</p>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <label htmlFor="title" className='form-label text-gray'>Answer</label>
                                                                                    <p>{vote_result[0]?.answers?.answer}</p>
                                                                                </div>
                                                                                <hr className='mt-4 mb-2' />
                                                                                {Array.isArray(vote_result) && vote_result.map((res: any, index: any) =>
                                                                                    <>
                                                                                        <div className="col-12" key={index}>
                                                                                            <div className="votes-list d-flex justify-content-between pb-3">
                                                                                                <div className="voter-image d-flex align-items-center gap-3 ">
                                                                                                    <img src={res?.user?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.user?.profile_pic}` : defaultUserImg} alt="image" className='img-fluid' />
                                                                                                    <p>{res?.user?.name}</p>
                                                                                                </div>
                                                                                                <Link to={`/user/${res?.user?.id}`} className='text-decoration-none text-black' >
                                                                                                    <button className="btn view-btn border view-voter d-flex gap-2 align-items-center" data-bs-dismiss="modal">
                                                                                                        <img src={viewImage} alt="icons" className='img-fluid' />
                                                                                                        View
                                                                                                    </button>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr className='m-0' />
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <p className='text-center'>No Voting Yet</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}

export default ViewPolls