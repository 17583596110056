enum OrderStatus {
    PLACED = "PLACED",
    PAID = "PAID",
    SHIPPED = "SHIPPED",
    CONFIRMED = "CONFIRMED",
    CANCELLED = "CANCELLED",
    DELIVERED = "DELIVERED",
    DISPUTE = "DISPUTE",
    RETURNED = "RETURNED",
    REFUND_IN_PROGESS = "REFUND_IN_PROGESS",
    REFUNDED = "REFUNDED",
    PENDING_CANCELLATION = "PENDING_CANCELLATION"
}
enum Roles {
    DASHBOARD = "1",
    USERS = "2",
    CLEARWAY_ZONE = "3",
    POLL = "4",
    VEHICLES = "5",
    DONATIONS = "6"
}
enum Invoice {
    SELLER = 'SELLER',
    USER = 'USER',
}
export default {
    OrderStatus,
    Roles,
    Invoice,
}