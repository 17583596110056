import henceforthApi from '../../utils/henceforthApi'
import React, { useRef, useState } from 'react'
import { GlobalContext } from '../../context/Provider'
import { toast } from 'react-toastify'
import defaultUserImg from '../../assets/image/defaultUserImg.jpg';
import camera from '../../assets/icons/camera_red.svg'

export const rolesValue = [
    { role: "Dashboard", value: 1 },
    { role: "User", value: 2 },
    { role: "Clearwayzone", value: 3 },
    { role: "Poll's", value: 4 },
    { role: "Vehicle's", value: 5 },
    { role: "Donations", value: 6 },
]
const AddStaff = () => {

    const { authState } = React.useContext(GlobalContext);
    henceforthApi.setToken(authState.access_token);
    let ref1 = useRef() as any
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null as any)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstEye, setFirstEye] = useState(true);
    const [passwordError, setPasswordError] = useState("")
    const [roles, setRoles] = useState([] as Array<number>)
    const [staffMembers] = useState(rolesValue as Array<any>)

    const fileupload = async (file: any) => {
        let files = file.target.files[0]
        setSelectedFile(files)
    }

    const handleInput = (e: any) => {
        let value = e.target.value
        let prev = roles;
        let itemIndex = prev.indexOf(Number(value));
        if (itemIndex !== -1) {
            prev.splice(itemIndex, 1);
        } else {
            prev.push(Number(value));
        }
        prev.sort()
        setRoles([...prev]);
    }

    const handlesubmit = async (e: any) => {
        e.preventDefault()
        const items = {
            name,
            email,
            roles,
            password,
        } as any
        setLoading(true)
        try {
            let apires: any
            if (selectedFile) apires = await henceforthApi.Common.uploadFile('profile_pic', selectedFile)
            if (apires) items['profile_pic'] = apires.image
            let resApi = await henceforthApi.Staff.add(items)
            toast.success(resApi.message)
            window.history.back()
        } catch (error: any) {

            if (error.response.body.message.email) return toast.error(error.response.body.message.email[0])
            if (error.response.body.message.password) return toast.error(error.response.body.message.password[0])
            if (error.response.body.message.roles) return toast.error(error.response.body.message.roles[0])

        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <h3 className="px-4 py-4 bg-white border-bottom">Add Staff</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-5">
                                {/* title  */}
                                <div className="common-card bg-white rounded border">
                                    <div className="common-card-title py-3 px-4 border-bottom">
                                        <h5>Add Staff</h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content p-4">
                                        <form onSubmit={handlesubmit}>
                                            {/* Upload image */}
                                            <div className='upload-fields-box mb-3'>
                                                <div className='profile-edit-image text-center mb-4 '>
                                                    <div className="edit-pofile-image">
                                                        <img src={selectedFile ? URL.createObjectURL(selectedFile) : defaultUserImg} alt="img" className='edit-profile-image img-fluid' />
                                                    </div>
                                                    <div className='profile-edit-upload'>
                                                        <img src={camera} alt="image" className='img-fluid' />
                                                        <input type="file" onChange={fileupload} accept='image/png,image/jpeg,image/jpg' />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Name */}
                                            <div className='form-fields-box mb-3'>
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                                                <input type="text" className="form-control rounded-0" id="exampleFormControlInput1" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} name="name" required />
                                            </div>
                                            <div className='form-fields-box mb-3'>
                                                <label htmlFor="exampleFormControlInput2" className="form-label">Email</label>
                                                <input type="text" className="form-control rounded-0" id="exampleFormControlInput2" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} name="email" required />
                                            </div>
                                            <div className='form-fields-box mb-3 position-relative'>
                                                <label htmlFor="exampleFormControlInput3" className="form-label">Password</label>
                                                <input type={firstEye ? 'password' : 'text'} className="form-control rounded-0" id="exampleFormControlInput3" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} name="email" required />
                                                <div className="input-group-append">
                                                    <i className={`fa ${!firstEye ? `fa-eye-slash ${passwordError ? `text-danger` : `text-secondary`} ` : `fa-eye ${passwordError ? `text-danger` : `text-secondary`}`}`}
                                                        onClick={() => setFirstEye(!firstEye)}
                                                        aria-hidden="true"
                                                        style={{ position: 'absolute', bottom: '17px', right: '15px' }}
                                                    >
                                                    </i>
                                                </div>
                                            </div>
                                            {/* Role */}
                                            <div className='form-radio-box mb-4 pb-2'>
                                                <div className='form-check-inner d-flex gap-4 flex-wrap'>
                                                    {staffMembers?.map((res: any, index: number) =>
                                                        <div key={index} className="form-check">
                                                            <input className="form-check-input" type="checkbox" value={res?.value} checked={roles.includes(res.value)} ref={ref1} onChange={(e: any) => handleInput(e)} id={`roleslist${index}`} />
                                                            <label className="form-check-label ms-1" htmlFor={`roleslist${index}`}>
                                                                {res.role}
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* Button  */}
                                            <div className='signin-button-box'>
                                                <ul className='list-unstyled d-flex gap-2'>
                                                    <li><button type='submit' className='btn btn-primary py-2'>Add</button></li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </>
    )
}
export default AddStaff;