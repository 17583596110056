import henceforthApi from '../../utils/henceforthApi'
import React from 'react'
import { GlobalContext, handleError } from '../../context/Provider'
import { toast } from 'react-toastify'
import { Select } from 'antd';
import uiSettings from '../../utils/uiSettings';
import { useLocation, useMatch } from 'react-router-dom';
import Placeholder from '../common/Placeholder';

const AddEditVehicle = () => {

    const match = useMatch('vehicle/:id/edit')
    const { authState } = React.useContext(GlobalContext);
    const location = useLocation()

    henceforthApi.setToken(authState.access_token);
    const [loading, setLoading] = React.useState(false)
    const [modelYear, setModelYear] = React.useState<any>('')
    const [vehicleType, setVehicleType] = React.useState('SUV')

    let handleAddEdit = ''
    if (location.pathname === '/vehicle/add') {
        handleAddEdit = 'Add'
    }
    else {
        handleAddEdit = 'Edit'
    }



    const [state, setState] = React.useState({
        vehicle_name: '',
        vehicle_model: '',
    })
    const handleState = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })

    }

    const initialize = async () => {
        try {
            setLoading(true)
            let res = await henceforthApi.Vehicles.get(match?.params.id as string)
            setVehicleType(res.data.type)
            setState({
                ...state,
                vehicle_name: res.data.car,
                vehicle_model: res.data.model,
            })
            setModelYear(res.data.model_year)
        } catch (error) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    }

    const handlesubmit = async (e: any) => {
        e.preventDefault()
        const items = {
            car: state.vehicle_name,
            model: state.vehicle_model,
            type: vehicleType,
            model_year: modelYear
        } as any
        let resApi = {} as any
        const d = new Date();
        let currentYear = d.getFullYear()
        if (modelYear > currentYear) return toast.warn('Please Select a valid model year')
        try {
            setLoading(true)
            if (handleAddEdit === 'Add') {
                resApi = await henceforthApi.Vehicles.add(items)
            }
            else {
                resApi = await henceforthApi.Vehicles.edit(match?.params?.id, items)
            }
            toast.success(resApi.message)
            window.history.back()
        } catch (error: any) {
            if (error.response.body.message.car) return toast.error(error.response.body.message.car[0])
            if (error.response.body.message.model) return toast.error(error.response.body.message.model[0])
            if (error.response.body.message.type) return toast.error(error.response.body.message.type[0])
            if (error.response.body.message.model_year) return toast.error(error.response.body.message.model_year[0])
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (handleAddEdit === 'Edit') {
            initialize()
        }
    }, [])

    return (
        <>
            {/* breadcrum  */}
            <section className="breadcrum-box py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {/* title  */}
                            <h3 className="px-4 py-4 bg-white border-bottom">{handleAddEdit} Vehicle</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5 col-xxl-5">
                                {/* title  */}
                                <div className="common-card bg-white rounded border">
                                    <div className="common-card-title py-3 px-4 border-bottom">
                                        <h5>{handleAddEdit} Vehicle</h5>
                                    </div>
                                    {/* form  */}

                                    {loading ? <Placeholder length={6} /> : <div className="common-card-content p-4">
                                        <form onSubmit={handlesubmit}>
                                            {/* Name */}
                                            <div className='form-fields-box mb-3'>
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Vehicle Name</label>
                                                <input type="text" className="form-control rounded-0" id="exampleFormControlInput1" placeholder='Enter name vehicle name' value={state.vehicle_name} onChange={handleState} name="vehicle_name" required />
                                            </div>
                                            <div className='form-fields-box mb-3'>
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Vehicle Model</label>
                                                <input type="text" className="form-control rounded-0" id="exampleFormControlInput1" placeholder='Enter name vehicle model' value={state.vehicle_model} onChange={handleState} name="vehicle_model" required />
                                            </div>
                                            <div className='form-fields-box mb-3'>
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Vehicle Type</label>
                                                <div className="input-group mb-3" id="exampleFormControlInput1">
                                                    <Select
                                                        className="form-control py-2"
                                                        style={{ width: '100%' }}
                                                        placeholder="Please select day"
                                                        value={vehicleType}
                                                        onChange={setVehicleType}
                                                        options={uiSettings.vehiclesType}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-fields-box mb-3'>
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Model Year</label>
                                                <input type="text" className="form-control rounded-0" id="exampleFormControlInput1" placeholder='2023' value={modelYear} onChange={(e: any) => setModelYear(e.target.value.replace(/\D/g, ""))} maxLength={4} name="vehicle_model_year" required />
                                            </div>
                                            <div className='signin-button-box'>
                                                {handleAddEdit === 'Add' ? <ul className='list-unstyled d-flex gap-2'>
                                                    <li><button type='submit' className='btn btn-primary py-2'>Add</button></li>
                                                </ul>
                                                    :
                                                    <ul className='list-unstyled d-flex gap-2'>
                                                        <li><button type='submit' className='btn btn-primary py-2'>Edit</button></li>
                                                    </ul>
                                                }
                                            </div>
                                        </form>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </>
    )
}
export default AddEditVehicle;